export const translationEnglish = {
  /**
   * Header Translations in English
   */
  NOTIFICATIONS: 'Notifications',
  MENU: 'Menu',
  SEARCH: 'Search',
  MY_PROFILE: 'My profile',
  SETTINGS: 'Settings',
  STATISTICS: 'Statistics',
  HELP: 'Help',
  LOG_OUT: 'Log out',
  CONNECT_TO_LAB: 'Connect to lab',

  /**
   * Menu Translations in English
   */
  LIST_VIEW: 'List view',
  STATUS_VIEW: 'Status view',
  CARD_VIEW: 'Card view',
  '1_PATIENT_AND_LAB': '1. PATIENT & LAB',
  '2_CONSTRUCTIONS': '2. CONSTRUCTIONS',
  '3_DELIVERY_INFORMATION': '3. DELIVERY INFORMATION',
  '4_UPLOAD_IMAGE': '4. UPLOAD IMAGES',
  '5_SIGN_AND_SEND': '5. SIGN & SEND',
  CANCEL_ORDER: 'CANCEL ORDER',
  ACCEPT_ORDER: 'ORDER RECEIVED',
  DECLINE_ORDER: 'DECLINE ORDER',
  ORDER_ID: 'Order id',
  RATE_ORDER: 'RATE',
  SEND_ORDER: 'SEND ORDER',
  RESEND_ORDER: 'RESEND',
  ARCHIVE_ORDER: 'ARCHIVE',
  UNARCHIVE_ORDER: 'UNARCHIVE',
  '1_PATIENT': '1. PATIENT',
  '3_LAB_AND_DELIVERY': '3. LAB AND DELIVERY',
  '4_SIGN_AND_SEND': '4. SIGN AND SEND',

  ORDER_APPROVED: 'APPROVED',

  /**
   * Login Page Translations in English
   */
  EMAIL: 'Email',
  PASSWORD: 'Password',
  REMEMBER_ME: 'Remember me',
  SIGN_IN: 'Sign in',
  FORGOT_THE_PASSWORD: 'Forgot the password',

  /**
   * Reset and Forgot password Page Translations in English
   */

  CONFIRM_PASSWORD: 'Confirm password',
  RESET: 'Reset',
  SUBMIT: 'Submit',
  GO_TO_LOGIN: 'Go to login',
  PASSWORD_RESET_FROM_PROVIDER:
    'Please note, password reset is send from our IT provider Denhub.',

  /**
   * New Order Translations in English
   */
  PATIENT_AND_LAB: 'Patient & Lab',
  ADD_PATIENT: 'Patient information',
  THREE_EASY_STEPS: 'Three easy steps',
  PERSONAL_IDENTITY_NUMBER: 'Personal identity number',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  CHOOSE_A_TECHNICIAN_LAB: 'Choose a technician lab',
  LAB: 'Lab',
  ORDERS: 'Orders',
  CLINICS: 'Clinics',
  USERS: 'Dentists',
  LABS_TECHNICIAN: 'Lab technician',
  LAB_TECHNICIANS: 'Manage technicians',
  CONSTRUCTIONS: 'Constructions',
  FIXED: 'Fixed',
  CROWN: 'Crown',
  SINGLE: 'Single',
  BRIDGE: 'Bridge',
  POST: 'Post',
  REMOVABLE: 'Removable',
  IMPLANT: 'Implant',
  RANGE: 'Range',
  CLICK_ON_BOTH_ABUTMENT_TEETH_TO_MARK_THE_BRIDGE:
    'Click on both abutment teeth to mark the bridge',
  MATERIAL: 'Material',
  SELECT: 'Select',
  E_MAX: 'E-Max',
  ACRYLIC: 'Acrylic',
  ZIRKONIA: 'Zirkonia',
  EMPRESS: 'Empress',
  TITAN: 'Titan',
  COMPOSITE: 'Composite',
  ALUMINIUM: 'Aluminum',
  GOLD: 'Gold',
  PORCELAIN: 'Porcelain',
  COBALT_CHROME: 'Cobalt Chrome',
  OTHER: 'Other',
  TYPE: 'Type',
  HERE: 'Here',
  COLOR: 'Color',
  SHADE: 'Shade',
  COLOR_SAMPLING_AT_LAB: 'Color sampling at lab',
  METAL: 'Metal',
  PONTIC: 'Pontic',
  DESIGN: 'Design',
  NO_METAL_MARGIN: 'No metal margin',
  PORCELAIN_MARGIN: 'Porcelain margin',
  SMALL_METAL_MARGIN: 'Small metal margin',
  LINGUAL_METAL_MARGIN: 'Lingual metal margin',
  SANITARY: 'Sanitary',
  BULLET: 'Bullet',
  MOD_RIDGE_LAP: 'Mod. ridge lap',
  FULL_RIDGE_LAP: 'Full ridge lap',
  OVATE: 'Ovate',
  OCCLUSION: 'Occlusion',
  CONTACT: 'Approximal contact',
  HARD: 'Hard',
  NORMAL: 'Normal',
  LIGHT: 'Light',
  NONE: 'None',
  REMOVE: 'REMOVE',
  SAVE: 'SAVE',
  DELIVERY: 'Delivery',
  INFORMATION: 'Information',
  DATE: 'Date',
  CLINIC: 'Clinic',
  ADD: 'Add',
  TYPE_COMMENTS_HERE: 'Type comments here',
  UPLOAD: 'Upload',
  IMAGES: 'Images',
  FILES: 'Files',
  CLICK_OR_DRAG_AND_DROP_YOUR_FILE_HERE: 'Click or drag and drop your file here',
  SIGN_AND_SEND: 'Sign & Send',
  CONFIRM_THE_ORDER: 'Confirm the order',
  YOU_CONFIRM_ALL_INFORMATION_IS_CORRECT: 'You confirm all information is correct.',
  SAMPLE_DISINFECTED: 'Sample disinfected',
  DROP_IMAGES_HERE_OR_CLICK_TO_UPLOAD: 'Drop images here or click to upload',
  DROP_FILES_HERE_OR_CLICK_TO_UPLOAD: 'Drop files here or click to upload',
  ORDER_CREATED: 'Order created',
  ORDER_MODIFIED: 'Order modified',
  ORDER_UPDATED: 'Order updated',
  ORDER_PRICE_NEGATIVE: 'The total price can not be negative',
  ORDER_RESENT: 'Order resent',
  SELECT_COLOR: 'Select color',
  SELECT_SHADE: 'Select shade',
  CHOOSE_STEP: 'Choose Step',
  STEP: 'Step',
  SELECT_MATERIAL: 'Select material',
  COMMENTS: 'Comments',
  SELECT_METAl_DESIGN: 'Select metal design',
  SELECT_OCCLUSION: 'Select occlusion',
  SELECT_CONTACT_TYPE: 'Select contact type',
  CONTACT_TYPE: 'Contact type',
  SELECT_PONTIC_DESIGN: 'Select pontic design',
  PLEASE_CONNECT_A_LAB: 'Please connect to a lab first',
  REQUIRED_FIELD: 'Required Field',
  DELIVERY_ADDITIONAL_INFO: 'Delivery information',
  ADDITIONAL_INFO: 'Comments',
  CHOOSE_THE_TWO_END_POINTS_OF_THE_BRIDGES:
    'Choose the two end points of the bridge',
  CHOOSE_TEETH_TO_BE_REPLACED: 'Choose teeth to be replaced',
  UPPER_LOWER_JAW_IMAGE: 'Image on upper/lower jaw',
  IMPLANT_STEP: 'Implant step',
  DENTURE_STEP: 'Denture step',

  /**
   * New Order 2.0 Translations in English
   */
  PATIENT_INFO: 'Patient info:',
  PATIENT_CATEGORY: 'Patient category',
  SELECT_CATEGORY: 'Select category',
  NEXT_STEP: 'Next',
  PREVIOUS_STEP: 'Previous',
  ORTHODONTICS: 'Orthodontics',
  SNORING_RAILS: 'Snoring Rails',
  BITETRAYS: 'Bitetrays',
  ADD_FILES_OR_IMAGES: 'Add files or images:',
  CHOOSE_LAB_AND_DELIVERY_DETAILS: 'Choose lab and delivery details',
  RANK: 'Rank',
  PRICE: 'Price',
  DELIVERY_LOCATION: 'Delivery location',
  REASON_FOR_EXCEPTION: 'Reason for exception',
  SELECT_REASON:
    'Select one or multiple exceptions regarding why you are not choosing the preselected lab.',
  DISTANCE: 'DISTANCE',
  QUALITY: 'QUALITY',
  DELIVERY_TIME: 'DELIVERY TIME',
  PATIENT_PREFERENCE: 'PATIENT PREFERENCE',
  REASON_LANGUAGE: 'LANGUAGE',
  ACTIONS: 'Actions',
  CONFIRM_INFORMATION: 'Confirm information:',
  PERSONAL_NUMBER: 'Personal number',
  ACTION: 'Action:',
  CONSTRUCTION_TYPE: 'Construction type:',
  LAB_AND_DELIVERY_DETAILS: 'Lab and delivery details',
  EXCEPTION: 'Exception',
  REASON: 'Reason',
  PRODUCTION_ABROAD: 'Production abroad',
  FILES_AND_IMAGES: 'Files and images',
  BY_SENDING_YOU_CONFIRM: 'By sending you confirm that all information is correct!',
  NO_OF_PONTIC: 'No of pontic',
  IMPLANT_STEPS: 'Implant steps',
  DENTURE_STEPS: 'Denture steps',
  OTHER_REMOVABLE: 'Other',
  TO_HOME_PAGE: 'Back to order list',
  COMMENT: 'Comment',
  BACKGROUND_COLOR_CODE: 'Background Color code',
  ENTER_VALID_HEX_BACKGROUND_COLOR_CODE: 'Enter valid hex background color code',
  TEXT_COLOR_CODE: 'Text Color code',
  ENTER_VALID_HEX_TEXT_COLOR_CODE: 'Enter valid hex text color code',
  SELECT_CLASP_TEETH: 'Select clasp teeth',
  SELECT_CLASP_MATERIAL: 'Select clasp material',
  SELECT_FACIAL_LOOP: 'Select facial loop',
  SELECT_CLASP_TYPES: 'Select clasp types',
  SELECT_SPRING_TYPES: 'Select spring types',
  SELECT_SPRING_TEETH: 'Select spring teeth',
  ENTER_PROTRUSION_IN_MM: 'Enter protrusion in mm',

  // Confirm Order Translation in English
  ORDER_NUMBER: 'Order number',
  PATIENT_NAME: 'Patient name',
  PRINT_THE_ORDER_AND_FINISH: 'Print the order & Finish',
  MAKE_SURE_YOU_ADD_THIS_PAGE_ON_TOP_OF_THE_BOK_YOU_AR_SENDING_TO_THE_LABORATORY:
    'Make sure to put the printed receipt in the box you are sending to the lab.',
  THANK_YOU_FOR_YOUR_ORDER: 'Thank you for your order',

  // Order List Translation in English
  PATIENT: 'Patient',
  DENTIST: 'Dentist',
  STATUS: 'Status',
  DELIVERY_DATE: 'Delivery date',
  ADD_USER: 'ADD DENTIST',
  ADD_CLINIC: 'ADD CLINIC',
  SENT_BY_DENTIST: 'SENT BY DENTIST',
  ACCEPTED_BY_LAB: 'RECEIVED BY LAB',
  ACCEPTED_BY_LAB_BUTTON: 'RECEIVE ORDER',
  DECLINE_BY_LAB: 'DECLINE BY LAB',
  SENT_BY_LAB: 'SENT BY LAB',
  SENT_BY_LAB_BUTTON: 'SEND TO CLINIC',
  DECLINED: 'DECLINED',
  GET: 'GET',

  /**
   * User Module Translations in English
   */
  USER_STATUS_UPDATED: 'Dentist status updated successfully',
  USER_CREATED: 'Dentist created successfully',
  ADD_NEW_USER: 'Add new dentist',
  NAME: 'Name',
  USER_NAME: 'User name',
  USER_EMAIL: 'User email',
  MAKE_ADMIN: ' Make admin',
  CHOOSE_CLINICS: 'Choose clinics',
  CANCEL: 'Cancel',
  ACTIVE_USER: 'Active',
  INACTIVE_USER: 'InActive',
  EDIT: 'Edit',
  UPDATE_USER: 'Update dentist',
  ROLE: 'Role',

  /**
   * Clinic Module Translations in English
   */
  PARENT: 'Parent',
  PARENT_NAME: 'Parent name',
  UPDATE_CLINIC: 'Update clinic',
  DELIVERY_ADDRESS: 'Delivery address',
  INVOICE_ADDRESS: 'Invoice address',
  STREET: 'Street',
  POSTCODE: 'Zip code',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: ' Are you sure you want to delete?',
  CITY: 'City',
  ACTIVE_CLINIC: 'Active clinic',
  INACTIVE_CLINIC: 'Inactive clinic',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ACTIVATE: 'Activate',
  DELETE: 'Delete',
  SUCCESSFULLY_UPDATED: 'Successfully updated',
  PLEASE_ENTER_VALID_PINCODE: 'please enter valid zip code',
  SUCCESSFULLY_ADDED: 'Successfully added',
  SUCCESSFULLY_DELETED: 'Successfully deleted',
  CLINIC_IMAGE: 'Clinic image',
  COST_CENTER: 'Ref/ Po/ Cost center',
  TELEPHONE: 'Telephone',
  EXTERNAL_INFO: 'External info',
  EXTERNAL_ID_1: 'External id 1',
  EXTERNAL_ID_2: 'External id 2',

  /**
   * Lab Module Translations in English
   */
  UPDATE_LAB: 'Update lab',
  ACTIVE_LAB: 'Active lab',
  INACTIVE_LAB: 'Inactive lab',
  LAB_IMAGE: 'Lab image',
  ADD_LAB: 'Add lab',
  CONFIRM: 'confirm',
  LABS: 'Labs',
  INVALID_PIN_CODE: 'Invalid zip code',

  /**
   * Lab-Technician Module Translations in English
   */
  UPDATE_LAB_TECHNICIAN: 'Update lab technician',
  ADD_LAB_TECHNICIAN: 'Add lab technician',
  TECHNICIAN: 'Technician',
  UPDATE: 'Update',
  CHOOSE_LABS: 'Choose labs',
  LAB_TECHNICIAN_CREATED: 'Lab technician created successfully ',
  LAB_TECHNICIAN_UPDATED: 'Lab technician updated successfully ',
  LAB_TECHNICIAN_STATUS_UPDATED: 'Lab technician  status updated successfully ',
  GENERAL_INFORMATION: 'General information',
  SHORT_DESCRIPTION: 'Short information',
  LONG_DESCRIPTION: 'Long information',
  SHORT_DESCRIPTION_MESSAGE:
    'This is the description that will appear in the list view',
  LONG_DESCRIPTION_MESSAGE:
    'This is the description that will appear on the lab profile page',
  LAB_NAME: 'Lab name',
  CURRENT_JOBS: 'Available constructions',
  SINGLE_CROWN: 'Single crown',
  IMPLANT_CROWN: 'Implant crown',
  IMPLANT_BRIDGE: 'Implant bridge',
  FULL_MAXILLA_DENTURE: 'Full maxilla denture',
  FULL_MANDIBLE_DENTURE: 'Full mandible denture',
  PARTIAL_MAXILLA_DENTURE: 'Partial maxilla denture',
  PARTIAL_MANDIBLE_DENTURE: 'Partial mandible denture',
  DAYS_TO_DELIVERY: 'Days to delivery',
  DOCUMENTS: 'Documents',
  MEDIA_FILES: 'Media files',
  LOGO_OF_THE_COMPANY: 'Logo of the company',
  STAFF: 'Staff',
  CHANGE_PICTURE: 'Change picture',
  OPENING_HOURS: 'Service hours',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  TO: 'To',
  FROM: 'From',
  CONTACT_INFORMATION: 'Contact information',
  SELECT_ALL_DAYS: 'Select all days',
  PLEASE_ENTER_EMAIL: 'Please enter email',
  PLEASE_ENTER_PHONE_NUMBER: 'Please enter phone number',
  PLEASE_ENTER_ADDRESS: 'Please enter address',
  POSTAL_CODE: 'Zip code',
  PLEASE_ENTER_CITY_NAME: 'Please enter city name',
  SITE_LINK: 'Site link',
  UPLOAD_FILE: 'Upload file',

  SOMETHING_WENT_WRONG: 'Something went wrong !',
  INFO: 'Info',
  DAYS_TO_DELIVERY_INFO:
    'From the day the sample is received and till it is delivered to the dentist (including postal services)',

  /**
   * order Module Translations in English
   */
  CURRENT: 'Current',
  DELIVERED: 'Delivered',
  IMAGE: 'image',
  DRAG_AND_DROP_HERE: 'or drag & drop here',
  BY: 'by',
  SUCCESS_UPLOAD: 'Successfully uploaded',
  TYPE_YOUR_MESSAGE: 'Type your message here...',
  ADDITIONAL_ORDER_INFORMATION: 'Additional order information',

  /**
   * Cancel Order Translations
   */
  CANCEL_THE_ORDER: 'Cancel the order',
  WHY_ARE_YOU_CANCELLING_THE_ORDER: 'Why are you cancelling the order ?',
  ADD_AN_EXPLANATION_FOR_THE_LAB: 'Add an explanation for the lab',
  WHEN_YOU_CANCEL_THE_ORDER_ALL_INFORMATION_WILL_BE_DELETED:
    'When you cancel the order, all the information will be deleted',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_THE_ORDER:
    'Are you sure that you want to cancel the order?',
  NO_CANCEL_THE_ORDER: " No, Don't Cancel the order",
  YES_CANCEL_THE_ORDER: 'Yes, Cancel the order',

  /**
   * Decline order Translations
   */
  DECLINE_THE_ORDER: 'Decline the order',
  WHY_ARE_YOU_DECLINING_THE_ORDER: 'Why are you declining the order?',
  ADD_AN_EXPLANATION: 'Add an explanation',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_DECLINE_THE_ORDER:
    'Are you sure that you want to decline the order?',
  NO_DECLINE_THE_ORDER: " No, Don't Decline the order",
  YES_DECLINE_THE_ORDER: 'Yes, Decline the order',

  /**
   * Accept the order
   */
  ACCEPT_THE_ORDER: 'Receive the order',

  /**
   * Rate Order Modal translations in English
   */
  SAVE_RATINGS: 'Save ratings',
  RATE_TEXT: 'Please, rate how satisfied you are with the delivery of order',
  MIN_AND_MAX_STARS: ' (5 stars maximum , 1 star minimum)',
  PROTHESES: 'Protheses',
  BITE_SPLINTS: 'Bite Splints',
  FIT: 'Fit',
  AESTHETIC: 'Aesthetic',
  BRIDGES: 'Bridges',
  CROWNS: 'Crowns',
  POSTS: 'Posts',
  /**
   * Resend the order
   */
  RESEND_ORDER_SUCCESS: 'Successfully resend the order',

  /**
   * Modify the order
   */
  MODIFY_ORDER_SUCCESS: 'Successfully modify the order',

  /**
   * Send order Translations
   */
  SEND_THE_ORDER: 'Approve and send the order',
  ARCHIVE_THE_ORDER: 'Archive the order',
  UNARCHIVE_THE_ORDER: 'Unarchive the order',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_ORDER_STATUS_TO:
    'Are you sure that you want to change the order status to',
  LAB_APPROVAL_CONFIRMATION_TEXT:
    'Are you sure you want to approve this order and change its status to',
  APPROVED_BY: 'Work is approved for delivery by',
  APPROVED_FOR_DELIVERY: 'Approved for delivery by',
  APPROVED_BY_SHORT: 'Approved by',
  APPROVE_ORDER: 'Approve & send',
  APPROVED_BY_INSTRUCTIONS:
    'It is also possible to simply type your name if you do not have a dedicated user in the list.',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_ARCHIVE_THE_ORDER:
    'Are you sure that you want to archive the order',
  THE_ORDER_WILL_BE_ARCHIVED_FOR_EVERYONE_IN_THE_SAME_ORGANIZATION:
    'The order will be archived for everyone in the organization.',
  ARCHIVE_THE_ORDER_ONLY_FOR_ME: 'Archive only for me',
  ARE_YOU_SURE_THAT_YOU_WANT_TO_UNARCHIVE_THE_ORDER:
    'Are you sure that you want to Unarchive the order',
  NO_CHANGE_THE_STATUS: " No, Don't change the status",
  NO_ARCHIVE_THE_ORDER: "No, Don't archive the order",
  NO_UNARCHIVE_THE_ORDER: "No, Don't Unarchive the order",
  YES_CHANGE_THE_STATUS: 'Yes, Change the status',
  YES_ARCHIVE_THE_ORDER: 'Yes, Archive the order',
  YES_UNARCHIVE_THE_ORDER: 'Yes, Unarchive the order',

  ALREADY_DECLINED: 'Already declined',
  FILE: 'file',
  ACCESS_DENIED: 'Access denied',
  SEND: 'Send',

  /**
   * Change Password Module Translations in English
   */
  CHANGE_PASSWORD: 'Change password',
  TYPE_OLD_PASSWORD: 'Type your old password',
  TYPE_NEW_PASSWORD: 'Type your new password',
  REPEAT_NEW_PASSWORD: 'Repeat your new password',
  NEXT: 'next',
  OLD_PASSWORD: 'Old password',
  NEW_PASSWORD: 'New password',
  REPEAT_NEW_PASSWORD_PLACE: 'Repeat new password',
  PASSWORD_NOT_MATCH: 'Password do not match',
  PASSWORD_SUCCESSFULLY_UPDATED: 'Password changed successfully',
  IMAGE_SUCCESSFULLY_UPLOADED: 'Image successfully uploaded',
  FILE_UPLOADED: 'File Uploaded',
  FILE_REMOVED: 'File Removed',
  OR_DRAG_AND_DROP_HERE: 'or drag & drop here',

  /**
   *  Dentist Setting module translation in english
   */
  PROFILE: 'Profile',
  ACCOUNT: 'Account',
  SYSTEM: 'System',
  SYSTEM_SETTINGS: 'System Settings',
  CONSTRUCTION: 'Construction',
  CONSTRUCTION_SETTINGS: 'Default construction properties',
  DEFAULT_CLINIC: 'Default clinic',
  PLEASE: 'Please',
  LANGUAGE: 'Language',
  SAVE_CHANGES: 'Save Changes',
  INSERT_MESSAGE_PLEASE: 'Please insert message first.',
  ENGLISH: 'English',
  SWEDEN: 'Sweden',

  /**
   * Connect to lab translation in english
   */
  SEARCH_LAB: 'Search lab',
  DESCRIPTION: 'Description',
  SHOW_ALL_LABS: 'SHOW ALL LABS',
  FIND_A_LAB: 'Find a lab',
  FIND_LAB_SEARCH_PLACEHOLDER: 'Type name or city here...',
  FIND_LAB_TEXT_FIRST: 'Search for labs by name or city. Or click ',
  FIND_LAB_TEXT_SECOND: ' to see you can connect to.',
  DESCRIPTION_OF_THE_MESSAGE: 'Description of the lab',
  DOWNLOAD: 'Download',
  OPENING_DAYS: 'Opening days',
  MON: 'Mon',
  TUE: 'Tue',
  WED: 'Wed',
  THU: 'Thu',
  FRI: 'Fri',
  SAT: 'Sat',
  SUN: 'Sun',
  LAB_DIS_CONNECTED: 'Lab Disconnected',
  LAB_CONNECTED: 'Lab Connected',
  CONNECT: 'Connect',
  DISCONNECT: 'Disconnect',
  DISCONNECT_TO_LAB: 'Disconnect',
  LAB_PROFILE: 'LAB PROFILE',
  NO_LABS_FOUND: 'No labs found!',

  /**
   * Some Other translations in English related to menu
   */
  SUPER_ADMIN: 'SUPER ADMIN',
  CLINIC_ADMIN: 'CLINIC ADMIN',
  LAB_ADMIN: 'LAB ADMIN',
  ALL_ORDERS: 'All orders',
  MY_SETTINGS: 'My settings',
  MANAGE_USERS: 'Manage dentists',
  EDIT_LAB: 'Edit lab',
  MANAGE_CLINICS: 'Manage Clinics',
  MANAGE_LABS: 'Manage labs',

  /**
   * Early Delivery Status and Message in English
   */
  EARLY_DELIVERY_WARNING_TEXT: '* Chosen date deviates from the labs delivery time',
  EARLY_DELIVERY: 'Early Delivery!',
  BACK_TO_SUPER_ADMIN: 'Back To Super Admin',

  /**
   * Notifications Module Translations in English
   */
  IS_ACCEPTED_BY_LAB: 'is received by lab.',
  IS_SENT_BY_LAB: 'is sent by lab.',
  IS_CANCELLED: 'is cancelled.',
  IS_DECLINED: 'is declined.',
  NEW_ORDER_ARRIVED: 'New order arrived.',
  NEW_ORDER_NOTIFICATION_PREFIX: 'New order',
  RECEIVED_NOTIFICATION_SUFFIX: 'received.',
  YOU_HAVE_A_NEW_MESSAGE_IN_ORDER: 'You have a new message in order',
  YOU_HAVE: 'You have',
  NEW_MESSAGES_IN_ORDER: ' new messages in order',
  ORDER: 'Order',
  THERE_ARE_NO_NEW_NOTIFICATIONS: 'There are no new notifications',
  CLEAR_ALL_NOTIFICATIONS: 'Mark all as read',

  /**
   * Removable Constructions Translations in English
   */
  COMPLETE_DENTURE: 'Complete Denture',
  PARTIAL_DENTURE: 'Partial Denture',
  TEMPORARY_DENTURE: 'Temporary Denture',
  BITETRAY: 'Bitetray',
  VALIO: 'Valplast',
  SOFT: 'Soft',
  SOFT_INSIDE: 'Soft Inside',
  DENTURES: 'Dentures',
  BITERAYS: 'Biterays',
  IMMEDIATE_DENTURE: 'Immediate Denture',
  INDIVIDUAL_TRAY: 'Individual Tray',
  BITE_BLOCK: 'Bite Block',
  DENTITION: 'Dentition',
  COMPLETE_CONSTRUCTION: 'Complete Construction',

  /**
   * For Constants Translations in English
   */
  METAL_PORCELAIN: 'Metal/Porcelain',
  METAL_ACRYLIC: 'Metal/Acrylic',
  MBP: 'MBP',
  METAL_AKRYL: 'Metal/Akryl',
  'PFM-GOLD': 'PFM-Gold',
  'PFM-TITAN': 'PFM-Titan',
  'PFM-COCR': 'PFM-CoCr',

  /**
   * Some Other Translations in English
   */
  PARENT_CLINIC: 'Parent clinic',
  NEW_ORDER: 'New order',
  CHOOSE_PARENT_CLINIC: 'Choose parent clinic',
  PASSWORD_CHANGE_SUCCESS: 'Password change successfully',
  VISIT_EMAIL_FOR_RESET: 'Visit email for password reset',
  NO_EMAIL_FOUND: 'No email found',
  NO_RESULT_FOUND: 'No result found',
  SINGLE_CROWNS: 'Single crowns',
  IMPLANT_BRIDGES: 'Implant bridges',
  IMPLANT_CROWNS: 'Implants',
  TOOTH: 'Tooth',
  SAMPLING_AT_LAB: 'Sampling at lab',
  TEETH: 'Teeth',
  PRINTED_DATE: 'Printed date',
  ORDER_CREATED_DATE: 'Order created date',
  RECEIVING_LAB: 'Receiving lab',
  FIXED_CONSTRUCTIONS: 'Fixed constructions',
  REMOVABLE_CONSTRUCTIONS: 'Removable constructions',
  CREATED: 'Created',
  DELIVERY_DESTINATION: 'Delivery destination',
  CANCELLED: 'Cancelled',
  REJECTED: 'Rejected',
  PATIENT_INFORMATION: 'Patient information',
  PICK_UP_LOCATION: 'Pick-up location',
  PICK_UP_ADDRESS: 'Pick-up address',
  YES: 'Yes',
  NO: 'No',
  PONTIC_DESIGN: 'Pontic design',
  METAL_DESIGN: 'Metal design',
  OCCLUSION_CONTACT: 'Occlusion contact',
  SELECT_OCCLUSION_CONTACT: 'Select occlusion contact',
  SELECT_METAL_DESIGN: 'Select metal design',
  PROFILE_NAME: 'Profile name',
  PROFILE_FIRST_NAME: 'Profile first name',
  PROFILE_LAST_NAME: 'Profile last name',
  PROFILE_EMAIL: 'Profile email',
  PLEASE_SELECT_CLINIC: 'Please select clinic',
  SELECT_LANGUAGE: 'Select language',
  ACCOUNT_SETTINGS: 'Your account',
  WRITE_A_COMMENT: 'Write a comment...',
  WRITE_A_COMMENT_FOR_POST_CONSTRUCTION: 'Write a comment for this post',
  COMMENT_FOR_POST_CONSTRUCTION: 'Comment for this post',
  COMMENT_FOR_SINGLE_CROWN_CONSTRUCTION: 'Comment for this single',
  COMMENT_FOR_IMPLANT_CROWN_CONSTRUCTION: 'Comment for this implant crown',
  COMMENT_FOR_BRIDGE_CONSTRUCTION: 'Comment for this bridge',
  COMMENT_FOR_IMPLANT_BRIDGE_CONSTRUCTION: 'Comment for this implant bridge',
  COMMENT_FOR_COMPLETE_DENTURE_CONSTRUCTION: 'Comment for this complete denture',
  COMMENT_FOR_BITETRAY_CONSTRUCTION: 'Comment for this bitetray',
  COMMENT_FOR_TEMPORARY_DENTURE_CONSTRUCTION: 'Comment for this temporary denture',
  COMMENT_FOR_PARTIAL_DENTURE_CONSTRUCTION: 'Comment for this partial denture',
  LAB_CONTACT: 'Lab contact',
  COMMENT_FOR_OTHER_CONSTRUCTION: 'Comment for this other',
  MODIFY_ORDER: 'MODIFY',
  SEE_ALL_ORDERS: 'See All Orders',
  REASON_FOR_CANCELLATION: 'Reason For Cancellation:',
  REASON_FOR_DECLINE: 'Reason For Decline:',
  UPPER_JAW: 'Upper jaw',
  LOWER_JAW: 'Lower jaw',
  CANT_CHANGE_ANY_PROPERTIES: "Can't change any of the properties",
  ORGANIZATION_NUMBER: 'Organization Number',
  ENTER_VALID_YEAR: 'Please Enter Valid Year (YYYYMMDD)',
  VERSION: 'Version',
  DENTHUB: 'DENTHUB',
  LARGE: 'A4',
  MINI: 'TICKET',
  PRINT_LARGE: 'A4',
  PRINT_MINI: 'Order ticket',
  AUTO_ARCHIVE_ORDERS: 'Automatic archive orders',
  DAYS: 'days',
  ARCHIVE_MESSAGE:
    'Automatically archive order with status SENT BY LAB and not updated after the given number of days',
  DAYS_NOT_IN_SERVICE_AND_HOLIDAYS: 'Days not in service and holidays',
  MARK_THE_DAYS_THE_LAB_IS_CLOSED_IN_THE_CALENDAR_BELOW:
    'Mark the days the lab is closed. Dentists will be able to send orders, but the suggested delivery will be on later date.',
  SELECT_RETAINMENT: 'Select retainment',
  SCREWED: 'Screwed',
  CEMENTED: 'Cemented',
  RETAINMENT: 'Retainment',
  FOR_SPECIALIZED_MEDICAL_PRODUCTS_ACCORDING_TO_LVFS:
    'For specialized medical products according to LVFS2003:11',

  // toastr error messages
  ERROR_IN_GETTING_LOGIN_LOGO_IMAGE: 'Error when getting login logo image.',
  ERROR_IN_GETTING_APP_VERSION: 'Error when getting app version.',
  ERROR_IN_GETTING_USER_DETAILS: 'Error when getting user details.',
  ERROR_IN_GETTING_NOTIFICATIONS: 'Error when getting notifications.',
  ERROR_IN_CHANGE_MESSAGE_STATUS: 'Error when change message status.',
  ERROR_IN_GETTING_ORDER: 'Error when getting order.',
  ERROR_IN_ADD_MESSAGE: 'Error when adding message.',
  ERROR_IN_UPDATE_ORDER: 'Error when updating order.',
  ERROR_IN_SAVING: 'Error when saving order.',
  ERROR_IN_UPDATE_USER: 'Error when updating user.',
  ERROR_IN_GETTING_CLINICS: 'Error when getting clinics.',
  ERROR_IN_GETTING_CLINIC: 'Error when getting clinic.',
  ERROR_IN_ARCHIVING_ORDER: 'Error when archiving order.',
  ERROR_IN_UN_ARCHIVING_ORDER: 'Error when un-archiving order.',
  ERROR_IN_CANCELLING_ORDER: 'Error when cancelling order.',
  ERROR_IN_APPROVING_ORDER: 'Error when approving order.',
  ERROR_IN_CHANGE_PASSWORD: 'Error when changing password.',
  ERROR_IN_UPDATE_CLINIC: 'Error when updating clinic.',
  ERROR_IN_ADD_CLINIC: 'Error when adding clinic.',
  ERROR_IN_DELETE_CLINIC: 'Error when deleting clinic.',
  ERROR_IN_DECLINE_ORDER: 'Error when declining order.',
  ERROR_IN_GETTING_LABS: 'Error when getting labs.',
  ERROR_IN_LOGIN_AS_SUPER_ADMIN: 'Error when login as super admin.',
  ERROR_IN_UPDATE_LAB: 'Error when updating lab.',
  ERROR_IN_ADD_LAB: 'Error when adding lab.',
  ERROR_IN_GETTING_LAB_DETAILS: 'Error when getting lab details.',
  ERROR_IN_CONNECT_LAB: 'Error when connecting lab.',
  ERROR_IN_DISCONNECT_LAB: 'Error when disconnecting lab.',
  ERROR_IN_GETTING_LAB_RATING: 'Error when getting lab rating.',
  ERROR_IN_DELETE_LAB: 'Error when deleting lab.',
  ERROR_IN_UPLOAD_STAFF_IMAGE: 'Error when uploading staff image.',
  ERROR_IN_UPLOAD_LOGO_IMAGE: 'Error when uploading logo image.',
  ERROR_IN_UPDATE_LAB_TECHNICIAN: 'Error when updating lab technician.',
  ERROR_IN_ADD_LAB_TECHNICIAN: 'Error when adding lab technician.',
  ERROR_IN_DELETE_LAB_TECHNICIAN: 'Error when deleting lab technician.',
  ERROR_IN_UPLOAD_FILE: 'Error when uploading file.',
  ERROR_IN_DELETE_FILE: 'Error when deleting file.',
  ERROR_IN_GETTING_MESSAGES: 'Error when getting messages',
  ERROR_IN_UPDATE_MESSAGE: 'Error when updating message.',
  ERROR_IN_SEND_MESSAGE: 'Error when sending message.',
  ERROR_IN_GETTING_LAB: 'Error when getting lab.',
  ERROR_IN_GETTING_ORDERS: 'Error when getting orders.',
  ERROR_IN_CREATING_ORDER: 'Error when creating order.',
  ERROR_IN_RATE_ORDER: 'Error when rating order.',
  ERROR_IN_SEARCH_LABS: 'Error when searching for labs.',
  ERROR_IN_GETTING_CLINIC_DETAILS: 'Error in clinic details.',
  ERROR_IN_DELETE_USER: 'Error when deleting user.',
  ERROR_IN_GETTING_USERS: 'Error when  getting users.',
  ERROR_EMAIL_ID_ALREADY_EXIST: 'Email ID already exist',

  // Other translations again
  EMAIL_AND_PASSWORD_NOT_MATCHED: 'Email and password not matching.',
  ERROR_IN_SEARCH: 'Error in search.',
  RATING: 'Rating',
  ERROR_IN_UPDATE_USER_PASSWORD: 'Error when updating user password.',
  PUBLISH_LAB_PROFILE: 'Publish lab profile',
  PUBLISH_INFO: 'Publish lab on Denthub',
  EXPORT_PRODUCTION: 'Export production',
  EXPORT_INFO: 'Dentist can choose to produce on export',
  ADDITIONAL_DAYS_TO_DELIVERY: 'Additional days to delivery',
  PRODUCE_THIS_ORDER_ON_EXPORT: 'Produce this order on export?',
  COCR: 'CoCr',
  DENTITION_WITH_SKELETON: 'Dentition with skeleton',
  SELECT_STEP: 'Select step',
  SEND_BY_DENTIST_TO: 'SENT BY DENTIST to',
  CHANGE_DATE: 'Change date',
  DELIVERY_DATE_CHANGED: 'Delivery date has been changed to:',
  AT: 'at',
  OTHER_MATERIAL: 'Other material',
  EMAIL_NOTIFICATIONS: 'E-mail notifications',
  SELECT_WHEN_TO_RECEIVE_EMAIL: 'Select when to receive email',
  SELECT_THE_DESTINATION: 'Select the destination',
  WHEN_THE_LAB_CHANGED_THE_DELIVERY_DATE: 'When the lab changed the delivery date',
  WHEN_A_NEW_ORDER_RECEIVED: 'When a new order is received',
  WHEN_A_NEW_MESSAGE_RECEIVED: 'When a new message is received',
  WHEN_AN_ORDER_IS_MODIFIED: 'When an order is modified',
  WHEN_AN_ORDER_IS_RATED: 'When an order is rated',
  WHEN_AN_ORDER_IS_CANCELLED: 'When an order is cancelled',
  WHEN_AN_ORDER_IS_RESEND: 'When an order is resend',
  ENTER_EMAIL_ADDRESS: 'Enter e-mail address...',
  GENERAL_APPLICATION_NOTIFICATIONS: 'General application notifications',
  WHEN_A_NEW_ORDER_IS_RECEIVED: 'When a new order is received',
  WHEN_A_MESSAGE_IS_RECEIVED: 'When a message is received',
  WHEN_THE_ORDER_WAS_MODIFIED: 'When the order was modified',
  WHEN_THE_LAB_CHANGE_THE_DELIVERY_DATE: 'When the lab change the delivery date',
  WHEN_THE_DELIVERY_DATE_HAS_PASSED: 'When the delivery date has passed',
  WHEN_THE_ORDER_IS_NOT_RECEIVED_BY_LAB_WITHIN:
    'When the order is not received by lab within',
  VIEW_SETTINGS: 'View settings',
  INDICATE_IN_RED_WHEN_THE_ORDER_HAS_BEEN_MODIFIED:
    'Indicate in red when the order has been modified',
  INDICATE_IN_RED_WHEN_THE_DELIVERY_DATE_HAS_PASSED:
    'Indicate in red when the delivery date has passed',
  DELIVERY_DATE_CHANGES: 'delivery date changes',
  IS_NOT_ACCEPTED_BY_LAB: 'is not accepted by lab',
  INVITE: 'Invite',
  INVITE_A_LAB_TO_DENTHUB: 'Invite a lab to Denthub',
  SEND_INVITE: 'Send invite',
  DONT_SEND_INVITE: "Don't send invite",
  EMAIL_ADDRESS: 'Email address',
  ERROR_IN_SENT_INVITATION: 'Error in sent invitation',
  SUCCESSFULLY_SENT: 'Successfully sent',
  WHEN_ORDER_IS_MODIFIED: 'When order is modified',
  WHEN_ORDER_IS_RESEND: 'When order is resend',
  IS_MODIFIED: 'is modified',
  IS_RESEND: 'is resend',
  INVITE_LAB_MODAL_DESCRIPTION:
    'An email will be sent to inform the lab that you want to order constructions for them. Please check that the email address to the lab is correct:',
  ORG_NUMBER: 'Org no.',
  // Organization setting
  CHOOSE_ORGANIZATION_SETTINGS: 'Choose organization settings',
  REPORTS: 'Reports',
  ORDER2: 'Order 2',

  // Manage action
  MANAGE_ACTIONS: 'Manage Actions',
  EDIT_ACTIONS: 'Edit Actions',
  ADD_AN_ACTION: 'Add an Action',
  EDIT_AN_ACTION: 'Edit an Action',
  ADD_MAIN_CATEGORY: 'Add Main Category',
  MAIN_CATEGORY: 'Main Category',
  MAIN_CAT: 'Main Cat',
  SUB_CAT: 'Sub Cat',
  SUB_CATEGORY: 'Sub Category',
  ALL: 'All',
  RED: 'Red',
  ADD_SUB_CATEGORY: 'Add Sub Category',
  EDIT_MAIN_CATEGORY: 'Edit Main Category',
  EDIT_SUB_CATEGORY: 'Edit Sub Category',
  SELECT_MAIN_CATEGORY: 'Select main category',
  SELECT_SUB_CATEGORY: 'Select sub category',
  SELECT_MATERIAL_GROUP: 'Select material group',
  CHOOSE_HOW_TO_SELECT_TEETH: 'Choose how to select teeth',
  SINGLE_TOOTH: 'Single tooth',
  UP_LOW_JAW: 'Up/low jaw',
  HIDDEN_TEXT: 'Hidden Text',
  INCLUDE: 'Include',
  MANDATORY: 'Mandatory',
  COLOR_SHADE: 'Color/Shade',
  NO_OF_UNITS: 'No of units',
  INTEGER: 'Integer',
  INT: 'Int.',
  HEADER: 'Header',
  NO_OF_DEICMALS: 'No. of deicmals',
  ADD_MATERIAL_GROUP: 'Add Material Group',
  EDIT_MATERIAL_GROUP: 'Edit Material Group',
  ADDED_MATERIALS: 'Materials',
  ADD_ACTION: '+ Add',
  ENTER_MATERIAL_NAME: 'Enter material name',
  MATERIAL_GROUP: 'Material Groups',
  CATEGORY_CREATED_SUCCESSFULLY: 'Category created successfully',
  CATEGORY_UPDATED_SUCCESSFULLY: 'Category updated successfully',
  CATEGORY_DELETED_SUCCESSFULLY: 'Category deleted successfully',
  ACTION_CREATED_SUCCESSFULLY: 'Action created successfully',
  ACTION_UPDATED_SUCCESSFULLY: 'Action updated successfully',
  ACTION_DELETED_SUCCESSFULLY: 'Action deleted successfully',
  ERROR_IN_GETTING_CATEGORIES: 'Error in getting categories',
  ERROR_IN_CREATING_CATEGORY: 'Error in creating category',
  ERROR_IN_UPDATING_CATEGORY: 'Error in updating category',
  ERROR_IN_DELETING_CATEGORY: 'Error in deleting category',
  ERROR_IN_GETTING_ACTIONS: 'Error in getting actions',
  ERROR_IN_CREATING_ACTION: 'Error in creating action',
  ERROR_IN_UPDATING_ACTION: 'Error in updating action',
  ERROR_IN_DELETING_ACTION: 'Error in deleting action',
  MATERIAL_CREATED_SUCCESSFULLY: 'Material created successfully',
  MATERIAL_UPDATED_SUCCESSFULLY: 'Material updated successfully',
  MATERIAL_DELETED_SUCCESSFULLY: 'Material deleted successfully',
  ERROR_IN_CREATING_MATERIAL: 'Error in creating material',
  ERROR_IN_UPDATING_MATERIAL: 'Error in updating material',
  ERROR_IN_DELETING_MATERIAL: 'Error in deleting material',
  ERROR_IN_GETTING_MATERIALS: 'Error in getting materials',
  CLASP_TEETH: 'Clasp teeth',
  CLASP_MATERIAL: 'Clasp material',
  ORTH_COLORS: 'Orth.colors',
  FACIAL_LOOP: 'Facial loop',
  CLASP_TYPES: 'Clasp types',
  SPRING_TYPES: 'Spring types',
  SPRING_TEETH: 'Spring teeth',
  EXPANSION_SCREW: 'Expansion screw',
  PROTRUSION: 'Protrusion',
  ORTHODONTICS_COLORS: 'Orthodontics colors',

  // Manage labs
  FILTER_LABS: 'Filter labs:',
  CONNECT_TO_LABS: 'Connect to labs',
  CONNECT_TO_NEW_LABS: 'Connect to new labs',
  PRICE_LISTS: 'Price lists',
  AVAILABILITY: 'Availability',
  LAB_AVAILABILITY: 'Lab Availability',
  SELECT_THE_DAYS_THE_LAB_IS_UNAVAILABLE: 'Select the days the lab is unavailable',
  DURING_UNAVAILABLE_DAYS_NO_ORDER_WILL_BE_RECEIVED:
    'During unavailable days, no order will be received.',
  BACK_HANDLING_LABS: 'Back handling lab',
  ORG_NUM: 'Org-number',
  ERROR_IN_CONNECT_LABS: 'Error when connecting labs.',
  YEAR: 'Year:',
  MONTHS: 'Months:',
  IN: 'in %',
  ERROR_IN_GETTING_PRICE_LIST: 'Error in getting price list',
  ERROR_IN_REMOVING_PRICE_LIST: 'Error in removing price list',
  ERROR_IN_ADDING_PRICE_LIST: 'Error in adding price list',
  PRICE_LIST_ADD_SUCCESS: 'price list added successfully',
  PRICE_LIST_REMOVED_SUCCESS: 'price list removed successfully',
  EDIT_LABS: 'Edit lab',
  ADDRESS: 'Address',
  TELEPHONE_NUMBER: 'Telephone number',
  WEB: 'Web',
  THIS_LAB_IS_DISABLED: 'This lab is disabled',
  CODE: 'Code',
  ADD_PRICE_LIST: 'Add price list',
  DISABLE_LAB: 'Disable Lab',
  ENABLE_LAB: 'Enable Lab',
  ENTER_LAB_RANKING: 'Enter lab ranking',

  // Order details new page
  ORDER_INFO: 'Order Info',
  INVOICE_NUMBER: 'Invoice number',
  INTERNAL_ORDER_ID: 'Internal order id',
  LAB_AND_DELIVERY: 'Lab and delivery',
  CLINIC_INFO: 'Clinic Info',
  TEL: 'Tel',
  CONTACT_INFO: 'Contact Info',
  ORDER_CREATED_BY: 'Order created by',
  CHANGE_TO: 'CHANGE TO',
  ADDED_MATERIAL_OR_ACTIONS: 'Added material or actions',
  MATERIAL_ACTION: 'Material/Action',
  NEW_FREE_TEXT: 'New free text...',
  QUANTITY: 'Quantity',
  SEARCH_OR_CHOOSE_OPTION: 'Search or choose option',
  WRITE_HERE: 'Write here...',
  ENTER: 'Enter...',
  APPROVE_THE_ORDER: 'APPROVE the order',
  APPROVE_WARNING_MESSAGE: 'Confirm if the price in the order is correct:',
  CONFIRM_PRICE: 'Yes, the price from the lab is correct and I approve the invoice!',
  CONFIRM_CERTIFY_PRICE:
    'Yes, the price from the lab is correct and I approve and certify the invoice!',
  RATE_ORDER_MODAL_MESSAGE: 'Are you satisfied with the order?',
  SATISFIED: 'Satisfied',
  NOT_SATISFIED: 'Not Satisfied',
  WHAT_ARE_YOUR_REMARKS: 'What are your remarks?',
  YOU_MUST_CHOOSE_AT_LEAST_ONE_REMARK: 'You must choose at least one remark.',
  ESTETICS: 'estetics',
  FITTING: 'Fitting',
  SERVICE_AND_AVAILABILITY: 'Service and availability',
  DEVIATION_FROM_DESCRIPTION: 'Deviation from description',
  RATE_THE_ORDER: 'Rate the order',
  NO_RATE_THE_ORDER: "No, Don't Rate the Order",
  YES_RATE_THE_ORDER: 'Yes, Rate the Order',
  PRICE_SPECIFICATION: 'Price Specification',
  ACTIONS_AND_MATERIALS: 'Actions and materials',
  Total: 'Total',
  TOTAL_SUM: 'Total sum',
  APPROVED: 'APPROVED',

  /**
   * Federated login
   */
  EXECUTING_FEDERATED_LOGIN: 'Logging in...',
  SELECT_ORGANIZATION: 'Select organization',
  YOU_HAVE_BEEN_LOGGED_OUT: 'You are now signed out!',
  FEDERATED_ERROR: 'Federated login error!',
  NO_FEDERATED_USER: 'No user exists for this login method!',

  // Manage permissions module
  MANAGE_PERMISSIONS: 'Manage permissions',
  PERMISSIONS: 'Permissions',
  ADD_ROLE: 'Add Role',
  EDIT_ROLE: 'Edit Role',
  ROLE_NAME: 'Role Name...',
  ERROR_IN_GETTING_ROLES: 'Error in getting roles',
  ERROR_IN_GETTING_PERMISSIONS: 'Error in getting permissions',
  ERROR_IN_GETTING_ROLE_PERMISSIONS: 'Error in getting role permissions',
  ERROR_IN_ADDING_ROLE: 'Error in saving role',
  ERROR_IN_UPDATING_ROLE: 'Error in updating role',

  TECHNICIAN_ADMIN: 'Dental Technician (Admin)',
  TECHNICIAN_USER: 'Dental Technician',
  DENTIST_ADMIN: 'Dentist (Admin)',
  DENTIST_USER: 'Dentist',
  DENTIST_CLINIC_MANAGER: 'Clinic Admin',
  LIMITED_DENTIST: 'Dentist',

  // Lab-technician page
  SELECT_ROLE: 'Select role',
  SELECT_LAB: 'Select lab',

  // Manage clinic page
  SELECT_PARENT_CLINIC: 'Select parent clinic',
  SELECT_DEFAULT_CLINIC: 'Select default clinic',

  // Customer settings
  CUSTOMER_SETTINGS_FOR: 'Customer Settings for',
  ID: 'ID',
  SETTING: 'Setting',
  DEFAULT_VALUE: 'Default Value',
  SET_VALUE: 'Set Value',
  DEFAULT_LANGUAGE: 'Default language',
  DEFAULT_LANGUAGE_DESCRIPTION: 'The default visual language in DentHub.',
  CURRENCY: 'Currency',
  CURRENCY_DESCRIPTION:
    'The used currency in the system, primarily used when action-based orders are used where prices per action is set.',
  CUSTOM_TRANSLATIONS: 'Custom translations',
  CUSTOM_TRANSLATIONS_DESCRIPTION:
    'Contains customer specific translations. The value should contain json formatted data on the same format as “language objects” in the codebase (for example “swedish-translation.js”).',
  EMAIL_EXT_USER_ASSIGNED_NOTIFICATION: 'Email external user assigned notification',
  EMAIL_EXT_USER_ASSIGNED_NOTIFICATION_DESCRIPTION:
    'Controls if an email notification should be sent when an order is shared with an external user.',
  DEFAULT_LOGIN_METHOD: 'Default login method',
  DEFAULT_LOGIN_METHOD_DESCRIPTION:
    'Controls which login page a user is sent to on logout',
  PTJ_ORGANIZATION: 'PTJ organization',
  PTJ_ORGANIZATION_DESCRIPTION:
    'Controls whether the organization is a PTJ organization',
  PTJ_SETTINGS: 'PTJ Settings',
  PTJ_SETTINGS_DESCRIPTION: 'Controls Settings for a PTJ organization',
  API_USERNAME: 'API Username',
  API_USERNAME_DESCRIPTION:
    'Sets the username to be used for API users associated with this customer',
  API_PASSWORD: 'API Password',
  API_PASSWORD_DESCRIPTION:
    'Sets the password to be used for API users associated with this customer',
  API_PUBLIC_KEY: 'API Public Key',
  API_PUBLIC_KEY_DESCRIPTION:
    'Sets the public key used when interacting with the API - Must be Base64 encode',
  ORDER_VERSION: 'Order version',
  ORDER_VERSION_DESCRIPTION:
    'Controls which version of new order module to be used.',
  ORDER_DOCUMENT_FORMAT: 'Order Document Format',
  ORDER_DOCUMENT_FORMAT_DESCRIPTION:
    'Controls the height and width of the order PDF document.',
  ORDER_DOCUMENT_MARGINS: 'Order Document Margins',
  ORDER_DOCUMENT_MARGINS_DESCRIPTION:
    'Controls the margins(in mm) on the order pdf document',
  LABEL_DOCUMENT_FORMAT: 'Label Document format',
  LABEL_DOCUMENT_FORMAT_DESCRIPTION:
    'Controls the height(in mm) and width(in mm) of the label(etikett) document',
  LABEL_DOCUMENT_MARGINS: 'Label Document margins',
  LABEL_DOCUMENT_MARGINS_DESCRIPTION:
    'Controls the margins(in mm) on the label(etikett) pdf document',
  ORDER_RECEIPT_FORMAT: 'Order Receipt format',
  ORDER_RECEIPT_FORMAT_DESCRIPTION:
    'Controls the height(in mm) and width(in mm) of the order receipt document',
  PATIENT_CATEGORY_DESCRIPTION:
    'Only for action-based orders. Activates the function patient category. This function is only used in actions based orders.',
  PATIENT_CATEGORIES: 'Patient categories',
  PATIENT_CATEGORIES_ADULT: 'Adult',
  PATIENT_CATEGORIES_CHILD: 'Child',
  PATIENT_CATEGORIES_ADULT_PREVENTIVE: 'Adult preventive',
  PATIENT_CATEGORIES_DESCRIPTION:
    'Only for action-based orders. The possible values of the patient categories. The value should contain json formatted data.',
  CHOOSE_LAB_EXCEPTIONS: 'Choose lab exceptions',
  CHOOSE_LAB_EXCEPTIONS_TECHNICAL: 'Technical',
  CHOOSE_LAB_EXCEPTIONS_DISTANCE: 'Distance',
  CHOOSE_LAB_EXCEPTIONS_LANGUAGE: 'Language',
  CHOOSE_LAB_EXCEPTIONS_PATIENT_NEEDS: 'Patient needs',
  CHOOSE_LAB_EXCEPTIONS_DESCRIPTION:
    'Only for action-based orders. The possible options that user can choose as exceptions. The value should contain json formatted data.',
  CLASP_MATERIAL_GOLD: 'Gold',
  CLASP_MATERIAL_STEEL: 'Steel',
  CLASP_MATERIAL_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  ORTHODONTICS_COLORS_RED: 'Red',
  ORTHODONTICS_COLORS_BLUE: 'Blue',
  ORTHODONTICS_COLORS_GREEN: 'Green',
  ORTHODONTICS_COLORS_ORANGE: 'Orange',
  ORTHODONTICS_COLORS_YELLOW: 'Yellow',
  ORTHODONTICS_COLORS_PURPLE: 'Purple',
  ORTHODONTICS_COLORS_PINK: 'Pink',
  ORTHODONTICS_COLORS_TRANSPARENT: 'Transparent',
  ORTHODONTICS_COLORS_DESCRIPTION:
    'Only for action-based orders. This is colors related to bitetrays and orthodontics, which differs from the already existing list of shade/colors.',
  FACIAL_LOOP_FACIAL_LOOP: 'Facial loop',
  FACIAL_LOOP_BASS_SPRING: 'Bass spring',
  FACIAL_LOOP_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  CLASP_TYPES_ADAM: 'Adam',
  CLASP_TYPES_BALL: 'Ball',
  CLASP_TYPES_ARROW: 'Arrow',
  CLASP_TYPES_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  SPRING_TYPES_Z_SPRING: 'Z-spring',
  SPRING_TYPES_EXPANSION_SCREW: 'Expansion screw',
  SPRING_TYPES_TELESCOPE_SCREW: 'Telescope screw',
  SPRING_TYPES_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  METAL_DESIGN_NO_METAL_MARGIN: 'No Metal Margin',
  METAL_DESIGN_PORCELAIN_MARGIN: 'Porcelain Margin',
  METAL_DESIGN_SMALL_METAL_MARGIN: 'Small Metal Margin',
  METAL_DESIGN_LINGUAL_METAL_MARGIN: 'Lingual Metal Margin',
  METAL_DESIGN_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  PONTIC_DESIGN_SANITARY: 'Sanitary',
  PONTIC_DESIGN_BULLET: 'Bullet',
  PONTIC_DESIGN_MOD_RIDGE_LAP: 'Mod. Ridge Lap',
  PONTIC_DESIGN_FULL_RIDGE_LAP: 'Full Ridge Lap',
  PONTIC_DESIGN_OVATE: 'Ovate',
  PONTIC_DESIGN_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  OCCLUSION_TYPE: 'Occlusion type',
  OCCLUSION_TYPE_HARD: 'Hard',
  OCCLUSION_TYPE_NORMAL: 'Normal',
  OCCLUSION_TYPE_LIGHT: 'Light',
  OCCLUSION_TYPE_NONE: 'None',
  OCCLUSION_TYPE_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  CONTACT_TYPE_HARD: 'Hard',
  CONTACT_TYPE_NORMAL: 'Normal',
  CONTACT_TYPE_LIGHT: 'Light',
  CONTACT_TYPE_DESCRIPTION:
    'Only for action-based orders. Button for choosing the available option of characteristics/settings.',
  TESTING_STEPS_TESTING: 'Take sample/test',
  TESTING_STEPS_COMPLETE: 'Complete construction',
  INVOICING_MODULE: 'Invoicing module',
  INVOICING_MODULE_DESCRIPTION: 'If the invoicing module is active or not.',
  INVOICING_PERIOD: 'Invoicing Period',
  INVOICING_PERIOD_DESCRIPTION:
    'The time period(in days) after which an Invoice should be created.',
  INVOICING_CONTACT_NAME: 'Contact name',
  INVOICING_CONTACT_NAME_DESCRIPTION: 'Contact name resposible for invoicing',
  INVOICING_CONTACT_EMAIL: 'Contact email',
  INVOICING_CONTACT_EMAIL_DESCRIPTION: 'Contact email resposible for invoicing',
  ORDER_PREFILL: 'Order prefill',
  ORDER_PREFILL_DESCRIPTION:
    'Enabled if the orders may be initiated by the order prefill endpoint.',
  ORDER_PREFILL_CLINIC_EXT_ID: 'Order prefill clinic ext_id',
  ORDER_PREFILL_CLINIC_EXT_ID_DESCRIPTION:
    'Decides which clinic external ID to used when matching to the clinic organizations.',
  RATING_REMARKS: 'Rating remarks',
  RATING_REMARKS_ESTHETICS: 'Esthetics',
  RATING_REMARKS_FITTING: 'Fitting',
  RATING_REMARKS_SERVICE_AVAILABILITY: 'Service availability',
  RATING_REMARKS_SERVICE_AND_AVAILABILITY: 'Service and availability',
  RATING_REMARKS_OCCLUSION: 'Occlusion',
  RATING_REMARKS_OTHER: 'Other',
  RATING_REMARKS_ESTETICS: 'Esthetics',
  RATING_REMARKS_DEVIATIONS_FROM_ORDER: 'Deviations from order',
  RATING_REMARKS_DEVIATION_FROM_DESCRIPTION: 'Deviation from description',
  RATING_REMARKS_DELIVERY_TIME: 'Delivery time',
  RATING_REMARKS_DESCRIPTION:
    'Only for action-based orders. The list of available remarks to choose from when the predefined lab is not chosen. The value should contain json formatted data.',
  APPROVED_STATUS: 'Approved status',
  APPROVED_STATUS_DESCRIPTION: 'Enables if the status APPROVED for dentists.',
  BACK_TO_MANAGE_CLINICS: 'Back to Manage Clinics',
  BACK_TO_MANAGE_LABS: 'Back to Manage Labs',
  ERROR_IN_GETTING_ORGANIZATION: 'Error in getting organization',
  ERROR_IN_UPDATING_ORGANIZATION: 'Error in updating organization',
  ERROR_IN_GETTING_SETTINGS: 'Error in getting settings',
  INVALID_EMAIL: 'Invalid email',
  SETTINGS_SAVED_SUCCESSFULLY: 'Settings saved successfully',
  IMPLANT_STEPS_INDIVIDUAL_TRAY: 'Individual tray',
  IMPLANT_STEPS_BITE_BLOCK: 'Bite Block',
  IMPLANT_STEPS_DENTITION: 'Dentition',
  IMPLANT_STEPS_DENTITION_WITH_SKELETON: 'Dentition with skeleton',
  IMPLANT_STEPS_SKELETON_TESTING: 'Skeleton testing',
  IMPLANT_STEPS_COMPLETE: 'Complete construction',
  DENTURE_STEPS_IMMEDIATE: 'Immediate denture',
  DENTURE_STEPS_INDIVIDUAL_TRAY: 'Individual tray',
  DENTURE_STEPS_BITE_BLOCK: 'Bite Block',
  DENTURE_STEPS_DENTITION: 'Dentition',
  DENTURE_STEPS_COMPLETE: 'Complete construction',
  PARTIAL_DENTURE_STEPS: 'Partial denture steps',
  PARTIAL_DENTURE_STEPS_INDIVIDUAL_TRAY: 'Individual tray',
  PARTIAL_DENTURE_STEPS_BITE_BLOCK: 'Bite Block',
  PARTIAL_DENTURE_STEPS_DENTITION: 'Dentition',
  PARTIAL_DENTURE_STEPS_COMPLETE: 'Complete construction',
  EXPORT_PRODUCTION_DESCRIPTION:
    'Only applicable for dentist customers. If the setting is enable the  dentists will be able to select export production or not, depending in if the specfic lab provides that service.',
  RATING_COLUMN: 'Rating column',
  RATING_COLUMN_DESCRIPTION:
    'Only for action-based orders. This enables/disables the rating column on the step where labs are selected.',
  RECENT_LAB_LIST: 'Recent used lab',
  RECENT_LAB_LIST_DESCRIPTION:
    'Activates the functionality where dentists can see the recently used labs based on previously created orders.',
  EXTERNAL_LAB_USERS: 'External lab users',
  EXTERNAL_LAB_USERS_DESCRIPTION: 'Enable/Disable the lab external user feature.',
  LIMIT_INFO_EXTERNAL_USERS: 'Limit Info for External lab users',
  LIMIT_INFO_EXTERNAL_USERS_DESCRIPTION: 'Limit Info for External lab users.',
  LAB_WORKFLOW: 'Lab workflow',
  LAB_WORKFLOW_DESCRIPTION: 'Enable/Disable the lab workflow feature.',
  PRIVATE_ELASTIC_DASHBOARD: 'Private Elastic space',
  PRIVATE_ELASTIC_DASHBOARD_DESCRIPTION:
    'The name of a private Elastic space. Feature is only enabled if name is given.',
  // Order filters
  MY_ORDERS: 'My Orders',
  APPROVED_BY_DENTIST: 'Approved by dentist',
  APPROVED_BY_DENTIST_BUTTON: 'Approve',
  SELECT_CLINIC: 'Select clinic',

  NO_LABS_WITH_MATCHING_PRICE_LISTS_FOUND:
    'No labs with matching price lists found!',
  PRINT_IT: 'Print',
  USER_IS_INACTIVE: 'User is inactive',
  ERROR_IN_SENDING_EMAIL: 'Error in sending email',
  SELECT_ACTIONS: 'Select actions...',
  WARRANTY: 'Warranty/remake',
  NO_TEETH_SELECTED: 'No teeth selected',

  // Some placeholder translations
  CATEGORY_NAME: 'Category name...',
  ENTER_INSTRUCTIONS: 'Enter instructions...',
  ENTER_HEADER: 'Enter header...',
  'NO.': 'No...',
  Name: 'Name...',
  SEARCH_FOR_A_ACTION_EITHER_BY_NAME_OR_NUMBER:
    'Search for a action either by name or number',
  FILTER_ON_NAME_OR_ORG_NUMBER: 'Filter on name or org-number',

  TESTING_STEPS: 'Testing steps',
  UPDATE_ORDER: 'UPDATE ORDER',
  '1_CONSTRUCTIONS': '1 CONSTRUCTIONS',
  '2_LAB_AND_DELIVERY': '2 LAB AND DELIVERY',
  '3_SIGN_AND_SEND': '3 SIGN AND SEND',
  DELIVERY_DETAILS: 'Delivery details',

  // Translation for old saved data of simple order construction
  'METAL_DESIGN_No Metal Margin': 'No Metal Margin',
  'METAL_DESIGN_Porcelain Margin': 'Porcelain Margin',
  'METAL_DESIGN_Small Metal Margin': 'Small Metal Margin',
  'METAL_DESIGN_Lingual Metal Margin': 'Lingual Metal Margin',
  PONTIC_DESIGN_Sanitary: 'Sanitary',
  PONTIC_DESIGN_Bullet: 'Bullet',
  'PONTIC_DESIGN_Mod. Ridge Lap': 'Mod. Ridge Lap',
  'PONTIC_DESIGN_Full Ridge Lap': 'Full Ridge Lap',
  PONTIC_DESIGN_Ovate: 'Ovate',
  OCCLUSION_TYPE_Hard: 'Hard',
  OCCLUSION_TYPE_Light: 'Light',
  OCCLUSION_TYPE_None: 'None',
  CONTACT_TYPE_Hard: 'Hard',
  CONTACT_TYPE_Light: 'Light',
  'IMPLANT_STEPS_Individual tray': 'Individual tray',
  'IMPLANT_STEPS_Bite Block': 'Bite Block',
  IMPLANT_STEPS_Dentition: 'Dentition',
  'IMPLANT_STEPS_Dentition with skeleton': 'Dentition with skeleton',
  'IMPLANT_STEPS_Skeleton testing': 'Skeleton testing',
  'IMPLANT_STEPS_Complete construction': 'Complete construction',

  // SITHS Login
  SIGN_IN_WITH_E_SERVICE_CARD_SITHS: 'Sign in with e-service card (SITHS)...',

  // BankID Login
  SIGN_IN_WITH_BANKID: 'Sign in with BankId...',

  SELECT_DATE: 'Select date...',
  ORDER_VERSION_SETTINGS_DOES_NOT_MATCH: 'Order version settings does not match.',
  ACTION_COST_PER_TOOTH: 'Action cost per tooth',
  COST_PER_TOOTH: 'Cost per tooth',
  ORDER_RESEND: 'Resend order',
  SAVE_ORDER_INFO: 'Order info saved',
  SAVE_LAB_HANDLE_REQUEST: 'Lab handle request saved',
  SUCCESSFULLY_UPDATED_LAB_FLAG: 'Lab flag updated successfully',

  // Reports
  REPORT: 'Report',
  CHOOSE_REPORT: 'Choose Report',
  FOR_WHOM: 'For whom',
  CHOOSE_DENTIST: 'Choose Dentist',
  CREATE: 'Create',
  CREATE_REPORT_HELP_TEXT: 'To create the report, fill in the fields above.',
  NO_REPORT_DATA: 'No report data available for the selected date period.',
  NUMBER_OF_ORDERS: 'Number of orders, per dentist',
  TOTAL_AMOUNT: 'Total amount',
  ECONOMY: 'Economy',
  LAB_RATING: 'Lab rating',
  RANKING_ADHERENCE: 'Ranking adherence',
  RANKING_VIOLATIONS: 'Ranking violations',
  DOWNLOAD_REPORT: 'Download report',
  SATISFIED_PERCENTAGE: 'Satisfied %',
  NOTSATISFIED_PERCENTAGE: 'Not Satisfied %',
  RATING_PER_CLINIC: 'Rating per clinic',
  SATISFACTION_PERCENTAGE: 'Satisfaction %',
  ORDER_RATINGS_FOR_LABS: 'Ratings, per order',
  ORDER_RATINGS_FOR_CLINICS: 'Ratings, per order',
  ORDERID: 'Order id',
  // TODO: Report TEMP VALUES, refactor this using customer settings
  ADULT: 'Adult',
  CHILD: 'Child',
  ADULT_PREVENTIVE: 'Adult preventive',
  TECHNICAL: 'Technical',
  PATIENT_NEEDS: 'Patient needs',
  ADHERENCE: 'Adherence',
  DEVIATIONS_FROM_ORDER: 'Deviations from order',
  ESTHETICS: 'Esthetics',
  SERVICE_AVAILABILITY: 'Service availability',
  NUMBER_OF_TOTAL_ORDERS: 'Total number of orders',
  NUMBER_OF_RATED_ORDERS: 'Number of rated orders',
  SELECT_STATUS: 'Select status',
  SYNCID: 'SyncID',
  CLINIC_CC: 'Clinic CC',
  ADHERENCE_PERCENTAGE: 'Adherence %',

  // Additional dentist
  PATIENT_AND_DENTIST: 'Patient and dentist',
  YOU: 'you',
  ADD_DENTIST: 'ADD DENTIST',
  CHANGE_DENTIST: 'CHANGE DENTIST',
  SELECT_DENTIST: 'SELECT DENTIST',
  SELECT_DENTIST_TEXT: 'Dentist is required',
  ADD_DENTIST_TO_ORDER: 'ADD DENTIST TO ORDER',
  FILTER: 'Filter',
  REMOVE_DENTIST: 'remove',
  DENTIST_INFORMATION: 'Dentist information',
  ADDITIONAL_DENTIST: 'Dentist',

  // Rating
  RATED_BY: 'Rated by',
  REMARKS: 'Remarks',

  // Select both jaw
  UPPER_LOWER_JAW: 'Upper and Lower jaw',

  // Exception/reason
  NOT_REQUIRE_EXCEPTION_WHEN_SELECTING_LAB:
    'Not require exception when selecting lab',

  // Pick up at clinics
  PICK_UP_AT_CLINICS: 'Pick up at clinics',
  SELECT_THE_CLINICS_WHERE_YOUR_LAB:
    'Select the clinics where your lab can provide the service to pick up the samples at the clinics.',
  CONNECTED_CLINICS: 'Connected clinics...',
  PICK_UP_AT_CLINIC: 'Pick up at clinic',
  REQUEST_PICK_UP_OF_THE_SAMPLE_AT_THE_CLINIC:
    'Request pick up of the sample at the clinic',
  PICK_UP_REQUESTED: 'Pick up requested?',
  HAS_THE_LAB_HANDLED_THE_REQUEST: 'Has the lab handled the request?',
  INVALID_TIME: 'Invalid time',

  // Invoicing
  INVOICING: 'Invoicing',
  ENABLE_INVOICING_OF_CLINICS: 'Enable invoicing of clinics',
  INVOICE_INFO: 'Invoice Info',
  CONFIGURATION: 'Configuration',
  CLINIC_SETTINGS: 'Clinic settings',
  ORG_NO: 'Org. No.',
  VAT_NO: 'VAT. No.',
  REFERENCE: 'Reference',
  PAYMENT_DETAILS: 'Payment Details',
  POSTGIRO: 'Postgiro',
  BANKGIRO: 'Bankgiro',
  BIC_SWIFT: 'BIC/Swift',
  IBAN_ACCOUNT_NUMBER: 'IBAN Account number',
  CITY_NAME: 'City name',
  STREET_NAME: 'Street name',
  INVOICE_NUMBER_GENERATION: 'Invoice number generation',
  MANUALLY_SET_PER_ORDER: 'Manually set per order',
  NUMBER_STARTING_FROM: 'Number starting from',
  STARTING_NUMBER: 'Starting number',
  MONTHLY_INVOICE_CONFIGURATION: 'Monthly invoice configuration',
  THESE_SETTING_ARE_USED_WHEN_MONTHLY_INVOICE_IS_SELECTED_FOR_THE_CUSTOMER_CLINIC:
    'These setting are used when monthly invoice is selected for the customer/clinic.',
  CREATE_THE_MONTHLY_INVOICE_ON_THIS_DAY_OF_THE_MONTH:
    'Create the monthly invoice on this day of the month',
  SELECT_INVOICE_METHOD: 'Select invoice method',
  ENABLE: 'Enable',
  PAYMENT_TERM: 'Payment term',
  INVOICE_METHOD: 'Invoice method',
  PER_ORDER: 'Per order',
  MONTHLY: 'Monthly',
  INVOICE_DATE: 'Invoice date',
  E_INVOICE_STATUS: 'E-Invoice status',
  READY_FOR_INVOICE: 'Ready for invoice',
  SENT: 'Sent',
  COMPLETE: 'Complete',
  INVOICE_CREATED: 'Invoice created',
  AMOUNT: 'Amount',
  VAT: 'VAT',
  '1_MONTH': '1 month',
  '3_MONTH': '3 month',
  ERROR_IN_GETTING_INVOICES: 'Error in getting invoices',
  INVALID_ZIP_CODE: 'Invalid zip code',
  ERROR_IN_CREATING_INVOICE: 'Error in creating invoice',
  INVOICE_SENT_DATE: 'Invoice sent',
  INVOICE_CREATED_SUCCESSFULLY: 'Invoice is ready for invoice',
  INVOICE_UPDATED_SUCCESSFULLY: 'Invoice updated successfully',
  ERROR_IN_UPDATING_INVOICE: 'Error in updating invoice',

  // Clinics new field
  THIS_CLINIC_IS_NOT_A_PICK_UP_AND_DELIVERY_PLACE:
    'This clinic is not a pick-up and delivery place',
  NO_USER_AVAILABLE: 'No Users Available',

  // Add action new field
  ENTER_THE_VAT: 'Enter the VAT (in %)',
  GLN: 'GLN',

  // Price-difference
  MATERIAL_GROUPS: 'Material groups',
  MATERIAL_GROUP_NAME: 'Material group name',
  MATERIAL_NAME: 'Material name',
  PRICE_DIFFERENCE: 'Price difference',
  PRICE_DIFFERENCE_UPDATED_SUCCESSFULLY: 'Price difference updated successfully',
  ERROR_IN_UPDATING_PRICE_DIFFERENCE: 'Error in updating price difference',
  ADDITION: 'Addition',
  DEDUCTION: 'Deduction',
  TOTAL: 'Total',

  // Sample method customer setting
  SAMPLE_METHOD: 'Sample Method',
  SAMPLE_METHOD_DESCRIPTION:
    'The method used by the dentist when the sample was taken.',
  SAMPLE_METHOD_LABEL: 'How was the sample taken?',
  SAMPLE_TAKEN: 'Sample taken',
  PHYSICAL: 'Physical',
  DIGITAL: 'Digital',

  // Sample package content
  SAMPLE_CONTENT: 'Sample content',
  SAMPLE_CONTENT_DESCRIPTION:
    'Sample content button used by the dentist when the sample was taken.',
  SAMPLE_CONTENT_SAMPLE: 'SAMPLE',
  SAMPLE_CONTENT_STUDY_MODEL: 'STUDY MODEL',
  SAMPLE_CONTENT_INDEX: 'INDEX',
  SAMPLE_CONTENT_PLASTER_MODEL: 'PLASTER MODEL',
  SAMPLE_CONTENT_IMPLANT_COMPONENTS: 'IMPLANT COMPONENTS',
  SAMPLE_CONTENT_DOWEL_PINS: 'DOWEL PINS',
  SAMPLE_CONTENT_COLOR_SAMPLE: 'COLOR SAMPLE',
  COMMENTS_ABOUT_THE_SAMPLE: 'Comments regarding the content in the box',
  SAMPLE_AND_PACKAGE_CONTENT: 'Sample and package content',
  CONTENT_IN_DELIVERY: 'Content in delivery:',
  CONTENT_COMMENTS: 'Comments:',
  SAMPLE_INFO:
    'Before changing status, please register the content in the received package:',
  INTERNAL_NUMBER: 'Internal number',
  NOT_GENERATED: 'Not Generated',
  PROSTHODONTICS: 'Prosthodontics',
  ERROR_IN_CREATING_PDF: 'Error in creating pdf',
  CURRENT_COMMENT: 'Current comment',

  // Role modal
  ROLE_TYPE: 'Role type',
  BOTH: 'Both',

  // Organization number
  INVALID_ORG_NO: 'Invalid Org. No.',

  // Existing patient order
  EXISTING_ORDER_INFO:
    'NOTE! This patient already has ongoing orders! If the order requires multiple steps to be completed, please\n' +
    'select an ongoing order below and “Resend” it instead of creating a new order. If the new order is not\n' +
    'related to any ongoing order, check the box below.',
  THE_NEW_ORDER_IS_NOT_RELATED_TO_EXISTING_ORDER:
    'The new order is not related to existing order!',

  // File size error
  FILE_IS_TOO_LARGE_TO_UPLOAD:
    'File is too large to upload. Maximum allowed file size is 800 MB.',

  NO_PERSONAL_IDENTITY_NUMBER: 'No personal identity number',
  NOTIFICATION_CLEARED: 'All notifications marked as read',
  ERROR_IN_CLEAR_NOTIFICATIONS: 'Error in clearing all notifications',

  // Automatic process for archive/approve/rating
  AUTO_APPROVE: 'Auto approve',
  AUTO_APPROVE_DESCRIPTION:
    'The Number of days after which automatic process archives, approves and rates (satisfied) orders',
  INVALID_VAT_NO: 'Invalid Vat No.',

  // Completed order in report
  ONLY_COMPLETED_IF_ORDERS_HAS_MULTIPLE_STEPS:
    'Only get completed orders if it has multiple steps',

  // Invoice pdf
  THE_PDF_INVOICE_COULD_NOT_BE_DOWNLOADED:
    'The PDF invoice could not be downloaded!',
  INVOICE_DOWNLOADED_SUCCESSFULLY: 'Invoice pdf downloaded successfully',

  // Locale format
  FORMATTING_OF_DATES_AND_NUMBERS: 'Formatting of dates and numbers',
  LOCALES: 'Locales',
  REGION: 'Region',

  // bank Id login
  SIGN_IN_WITH_SWEDISH_BANKID: 'Sign in with Swedish BankID',
  PERSONAL_IDENTIFICATION_NUMBER: 'Personal identification number',
  ENABLE_BANKID: 'Enable BankID',
  DISABLE_BANKID: 'Disable BankID',
  CANCEL_BANKID: 'Cancel BankID',
  BANK_ID_ENABLED: 'BankID is enabled',
  BANK_ID_NOT_ENABLED: 'BankID is not enabled',
  BANKID_ON_OTHER_DEVICE: 'BankID on another device',
  BANKID_ON_SAME_DEVICE: 'BankID on this device',
  SIGN_IN_WITH_BANK_ID: 'Sign in with BankID',
  SUCCESSFULLY_ACTIVATE_BANKID: 'Successfully activated BankID!',
  SUCCESSFULLY_DEACTIVATE_BANKID: 'Successfully deactivated BankID!',
  ERROR_ACTIVATE_BANKID: 'Error activating BankID!',
  ERROR_DEACTIVATE_BANKID: 'Error deactivating BankID!',
  ERROR_FEDERATED_OPERATION: 'Federated operation error!',
  SELECT_BANKID_METHOD: 'Select BankID method:',

  // report description
  REPORT_DESCRIPTION: 'Report info',
  ERROR_IN_GETTING_REPORT_DESCRIPTION: 'Error when getting report info',
  ERROR_IN_GETTING_REPORT: 'Error when getting report',

  // invoice number mandatory
  INVOICE_NUMBER_MANDATORY:
    'Invoice number is mandatory before setting ready for invoice',
  INVOICE_NUMBER_CONFIGURATION_NOT_SET:
    'Set Invoice number configuration before setting ready for invoice',

  ERROR_IN_GETTING_PATIENT_INFO: 'Error in getting patient info',
  NUMBER_OF_COMPLETED_ORDERS_PER_CLINIC: 'Number of completed orders, per clinic',
  ALL_CLINICS: 'All Clinics',
  ECONOMY_DONE_ORDERS_PER_CLINIC: 'Economy of done orders, per clinic',
  ECONOMY_DONE_ORDERS_PER_DENTIST: 'Economy of done orders, per dentist',
  NUMBER_OF_COMPLETED_ORDERS: 'Number of completed orders, per dentist',
  DUPLICATE_INVOICE_NUMBER: 'Duplicate invoice number',
  INVOICE_PRICE_MAY_NOT_BE_0: 'The invoice amount may not be 0',
  SOME_PARAMETERS_ARE_MISSING: 'Some parameters are missing',
  ORGANIZATION_MIS_MATCH: 'Organization mismatch',
  ORDER_NOT_FOUND: 'Order not found',
  INVOICE_ALREADY_SENT: 'Invoice already sent or completed',

  // invoice admin
  INVOICES: 'Invoices',
  INVOICE_INFORMATION: 'Invoice information',
  ERROR_IN_UPDATE_INVOICE_STATUS: 'Error in update invoice status',
  ALL_STATUS: 'All status',
  ALL_LABS: 'All labs',

  //other sample content
  OTHER_CONTENT: 'Other content',

  // client directory
  CLIENT_DIRECTORY: 'Client directory',
  DENTISTS: 'Dentists',
  ADD_DENTISTS: 'Add dentists',
  UPDATE_DENTISTS: 'Update dentists',
  PHONE_NUMBER: 'Phone number',
  ERROR_IN_CREATING_DENTIST: 'Error in creating dentist',
  ERROR_IN_GETTING_DENTISTS: 'Error in getting dentists',
  ADD_CLIENT: 'Add new client',
  CLIENT_DETAILS: 'Client details',
  VIEW_AND_EDIT: 'Edit',
  NEW_CLIENT: 'New client',
  UPDATE_CLIENT: 'Update client',
  SELECT_CLIENT: 'Select client',
  LOCAL_CLINIC_NOT_FOUND: 'Local clinic not found',
  ERROR_IN_UPDATE_CLIENT: 'Error in update client',
  DUPLICATE_LOCAL_CLINIC: 'Duplicate local clinic',
  ERROR_IN_ADD_CLIENT: 'Error in add client',
  CHECK_ALL: 'Check All',
  UNCHECK_ALL: 'Uncheck All',
  USE_COMMA_TO_SEPERATE_MULTIPLE_EMAIL_ADDRESSES:
    'Use comma to seperate multiple email addresses',
  ENTER_EMAIL_ADDRESSES: 'Enter email addresses',
  CLIENT_INFORMATION: 'Client information',

  // material lists
  MATERIAL_LISTS: 'Material lists',
  SELECT_MATERIAL_LIST: 'Select material list',
  CREATE_NEW_LIST: 'Create new list',
  NEW_MATERIAL_LIST: 'New material list',
  EDIT_MATERIAL_LIST: 'Edit material list',
  MATERIAL_LIST_NAME: 'Material list name',
  ADD_MATERIAL_LIST: 'Add material list',
  UPDATE_MATERIAL_LIST: 'Update material list',
  DEFAULT_MATERIAL_LIST: 'Default material list',
  DEFAULT_MATERIAL_LIST_SAVE_SUCCESSFULLY:
    'Default material list value save successfully',
  ERROR_IN_DEFAULT_MATERIAL_LIST: 'Error in default material list',
  MATERIALS: 'Materials',
  ADD_MATERIAL: 'Add material',
  UPDATE_MATERIAL: 'Update material',
  SELECT_WHAT_TO_SHOW: 'Select what to show',
  MATERIAL_CODE: 'Material code',
  BATCH_NUMBER: 'Batch number',
  DATE_ADDED: 'Date added',
  DATE_INACTIVE: 'Date inactive',
  BASE_PRICE: 'Base price',
  NEW_MATERIAL: 'New Material',
  EDIT_MATERIAL: 'Edit Material',
  INACTIVE_MATERIAL: 'Inactive material',
  AUTO_MATERIAL_REDUCTION: 'Auto material reduction',
  ALL_MATERIALS: 'All materials',
  ERROR_IN_CREATING_MATERIAL_LIST: 'Error in creating material list',
  MATERIAL_LIST_CREATED: 'Material list created',
  ERROR_IN_UPDATING_MATERIAL_LIST: 'Error in updating material list',
  MATERIAL_LIST_UPDATED: 'Material list updated',
  MATERIAL_UPDATED: 'Material updated',
  MATERIAL_CREATED: 'Material created',
  ASSOCIATED_MATERIAL_LIST: 'Associated material list',
  SELECT_LIST: 'Select list',
  NO_MATERIAL_LIST_SELECTED: 'No material list selected!',
  GENERAL_DISCOUNT: 'General discount (%)',
  DISCOUNTED_PRICE: 'Discounted price',
  SUCCESSFULLY_SAVED_ASSOCIATED_MATERIAL_LIST:
    'Successfully saved associated material list',
  ERROR_IN_SAVED_ASSOCIATED_MATERIAL_LIST: 'Error in saved associated material list',
  TOTAL_NO_VAT: 'Total, no VAT',
  TOTAL_WITH_VAT: 'Total with VAT',
  UNIT_PRICE: 'Unit price',
  FREE_TEXT: 'Free text',
  ERROR_IN_UPDATING_ORDER: 'Error in updating order',
  ADDED_MATERIAL: 'Added material',
  ADDED_ACTIONS: 'Added actions',
  SHOW_NOTES: 'Show notes for this action',
  ENTER_NOTES: 'Enter notes for this action',
  SUCCESSFULLY_UPDATED_ASSOCIATED_MATERIAL_LIST:
    'Successfully updated associated material list',
  ERROR_IN_UPDATING_ASSOCIATED_MATERIAL_LIST:
    'Error in updating associated material list',

  //order list page invoice status
  DUPLICATE_EMAIL: 'The given email address is already in use',
  READY_FOR_ORDER_INVOICE: 'Ready for invoice',
  INVOICE_SENT: 'Ready for invoice',
  INVOICING_COMPLETED: 'Ready for invoice',

  //delivery instruction
  DELIVERY_INSTRUCTION: 'Delivery instruction',

  // LOGISTICS
  LOGISTICS: 'Transport',
  LOGISTICS_APP: 'Transport',
  TRANSPORT_DAY: 'Pick-up day',
  LATEST_DELIVERY_DATE: 'Latest delivery date',
  ONLY_SHOW_PLANNED: 'Only show planned',
  FILTER_ON_CLINIC: 'Filter on clinic, address',
  PICK_UP_OF_SAMPLES_FROM_CLINICS_AND_DELIVERY_TO_LAB:
    'Pick-up of samples from clinics and delivery to lab',
  DELIVERY_OF_PACKAGE_FROM_LAB_TO_CLINIC: 'Delivery of package from lab to clinic',
  PACKAGES: 'Packages',
  DEADLINE: 'Deadline',
  OK: 'OK',
  PLANNED: 'Ordered',
  PICKED_UP: 'Picked up',
  MISSED: 'Missed',
  REVOKED: 'Revoked',
  MISSED_PICKUP: 'No pickup',
  MISSED_DELIVERY: 'Not delivered',
  SCHEDULE_A_NEW_PICK_UP: 'Schedule a new pick-up',
  UPDATE_A_PICK_UP: 'Update a pick-up',
  PLACE_OF_DELIVERY: 'Place of delivery',
  NO_OF_PACKAGES: 'No of packages',
  REQUESTED: 'Requested',
  ACTUAL: 'Actual',
  PICK_UP_SCHEDULED: 'Pick up scheduled',
  PICK_UP_UPDATED: 'Pick up updated',
  PICK_UP_DELETED: 'Pick up deleted',
  UPDATE_DELIVERY: 'Update delivery',
  SCHEDULE_A_NEW_DELIVERY: 'Schedule a new delivery',
  DELIVERY_SCHEDULED: 'Delivery scheduled',
  DELIVERY_UPDATED: 'Delivery updated',
  DELIVERY_DELETED: 'Delivery deleted',
  AFTER_8: 'After 8:00',
  BEFORE_8: 'Before 8:00',
  'No Deadline': 'No Deadline',
  TRANSPORT_SERVICES: 'Transport services',
  DENTHUB_TRANSPORT_SERVICE: 'Denthub Transport Service',
  TRANSPORT_INFORMATION:
    'In certain areas Denthub provides the service to transport the packages between clinics and labs. Here you can enable if you wish to use Denthub’s transport services.',
  DENTHUB_TRANSPORT_UPDATED: 'Denthub transport updated',
  PICK_UP_DATE: 'Pick-up date',
  DENTHUB_NOT_PICKUP: 'Denthub should NOT pick-up. Lab will use other resources.',
  OK_BUTTON_INSTRUCTION:
    'When clicking OK you will remove the pick-up of this package!',
  UPDATE_NEW_PICK_UP: 'Update new pick-up',
  MESSAGE_TO_DRIVER: 'Message to driver',

  //material list upload
  UPLOAD_INFORMATION: 'Upload information',
  TYPE_OF_UPLOAD: 'Type of upload',
  SELECT_TYPE: 'Select type',
  MATERIAL_LIST: 'Material list',

  // date type
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  YESTERDAY: 'Yesterday',

  //graphQl error
  BAD_REQUEST: 'Bad request',
  DATA_UPLOADED_SUCCESSFULLY: 'Data uploaded successfully',
  ERROR_IN_UPLOADING_DATA: 'Error in uploading data',
  GENERIC_ERROR_MSG: 'Something went wrong',

  //Invoice economy report
  ECONOMY_INVOICED: 'Economy of invoice orders, per dentist',

  NO_TRANSPORT_SERVICES_ON_THIS_DAY: 'No transport services on this day',
  MANDATORY_COMMENTS: 'Mandatory comments',
  MANDATORY_COMMENTS_DESCRIPTION:
    'If the setting is enable the comment field will be mandatory when creating new and resending orders.',
  REASON_FOR_EXCEPTION_DESCRIPTION:
    'If the setting is enable the system will require the user to select a reason of exception when selecting labs.',
  IMPLANT_STEPS_DESCRIPTION:
    'Only for action-based orders. The available steps for implants.',
  DENTURE_STEPS_DESCRIPTION:
    'Only for action-based orders. The available steps for dentures.',
  PARTIAL_DENTURE_STEPS_DESCRIPTION: 'The available steps for partial dentures.',
  TESTING_STEPS_DESCRIPTION:
    'Only for action based orders. Radio buttons for selecting testing or complete construction.',
  AUTO_APPROVE_CHECK_READY_FOR_INVOICE: 'Auto approve, check for ready for invoice',
  AUTO_APPROVE_CHECK_READY_FOR_INVOICE_DESCRIPTION:
    'If set to true the auto approve feature will also check if the order has the ready for invoice set to true',

  //action list
  ACTION_LISTS: 'Actions',
  SELECT_ACTION_LIST: 'Select action list',
  DEFAULT_ACTION_LIST: 'Default action list',
  CREATE_NEW_ACTION_LIST: 'New action list',
  NEW_ACTION_LIST: 'New action list',
  EDIT_ACTION_LIST: 'Edit action list',
  ACTION_LIST_NAME: 'Action list name',
  ADD_ACTION_LIST: 'add action list',
  UPDATE_ACTION_LIST: 'Update action list',
  ACTION_LIST_CREATED: 'Action list created',
  ACTION_LIST_UPDATED: 'Action list updated',
  ERROR_IN_CREATING_ACTION_LIST: 'Error in creating action list',
  ERROR_IN_UPDATING_ACTION_LIST: 'Error in updating action list',
  DEFAULT_ACTION_LIST_SAVE_SUCCESSFULLY: 'Default action list save successfully',
  ERROR_IN_DEFAULT_ACTION_LIST: 'Error in default action list',
  CATEGORIES: 'Categories',
  ADD_CATEGORY: 'Add category',
  NEW_CATEGORY: 'New category',
  EDIT_CATEGORY: 'Edit category',
  CATEGORY: 'Category',
  UPDATE_CATEGORY: 'Update category',
  ACTION_CATEGORY_NAME: 'Category name',
  CATEGORY_CREATED: 'Category created',
  CATEGORY_UPDATED: 'Category updated',
  ADD_NEW_ACTION: 'Add action',
  NEW_ACTION: 'New action',
  EDIT_ACTION: 'Edit action',
  ACTION_CODE: 'Action code',
  INACTIVE_ACTION: 'Inactive action',
  UPDATE_ACTION: 'Update action',
  ACTION_UPDATED: 'Action updated',
  ACTION_CREATED: 'Action created',
  ALL_CATEGORIES: 'All categories',
  ASSOCIATED_ACTION_LIST: 'Associated action list',
  NO_ACTION_LIST_SELECTED: 'No action list selected',
  SUCCESSFULLY_SAVED_ASSOCIATED_ACTION_LIST:
    'Successfully saved associated action list',
  ERROR_IN_SAVED_ASSOCIATED_ACTION_LIST: 'Error in saved associated action list',
  SUCCESSFULLY_UPDATED_ASSOCIATED_ACTION_LIST:
    'Successfully updated associated action list',
  ERROR_IN_UPDATING_ASSOCIATED_ACTION_LIST:
    'Error in updating associated action list',
  DISCOUNTED_PRICE_ERROR: 'Discounted price must be less from base price',
  SELECT_ACTION: 'Select action',
  ADDED_ACTION: 'Actions',

  //Invoiced orders
  INVOICED_ORDERS: 'Invoiced orders',
  INVOICE_STATUS: 'Invoice status',
  AMOUNT_EXCL_VAT: 'Amount excl VAT',

  //pickUp at clinics
  PICK_UP_AT_CLINICS_SAVE_SUCCESSFULLY: 'Pick up at clinics save successfully',
  ERROR_IN_PICK_UP_AT_CLINICS: 'Error in pick up at clinics',
  ADDITIONAL_PACKAGES: 'Additional packages',
  RECURRING_PICKUP: 'Recurring pickup',
  PICKUP_CLINICS_IS_EMPTY:
    'Pickup clinics is empty, please select at least one clinic',

  //no sample
  NO_SAMPLE: 'No sample',
  AUTO_APPROVE_DENTHUB: 'Auto Approved',
  AT_LEAST_ONE_ACTION_REQUIRED: 'Construction must have atleast one action',

  //3SHAPE
  THREE_SHAPE: '3SHAPE',
  SELECT_SCANS_FOR_UPLOADING: 'Select scans for uploading',
  SCANS_INFO: 'Test Testsson',
  SHAPE_UPLOAD_INFO: 'some info of 3shape upload',
  DOWNLOAD_ALL: 'Download all',
  THREE_SHAPE_UPLOADING_CLEAR_FOR: '3shape uploading clear for',
  DOWNLOADING_FROM_3SHAPE_SCANNER:
    'Downloading files from 3D scanner please wait for few minute...',
  FETCHING_SCAN_FROM_3D_SCANNER: 'Fetching scan from 3D scanner',

  PDF: 'Patient Specification',
  REGULATION_OF_MEDICAL_PRODUCT:
    'This specialized medical product is compliant with the regulations for medical products according to MDR (2017/745). In the case of needed exceptions, this will be mentioned in the explanation for the work concerned.',
  ORDER_SUMMARY: 'Order summary',
  CARE_ADVICE: 'Care Advice',
  REMOVABLE_PRODUCT_ADVICE_HEAD:
    'Removable products e.g. bite trays, dentures, etc.',
  REMOVABLE_PRODUCT_ADVICE:
    'Brush with a soft brush. Use liquid soap in lukewarm water. Put the product either in vinegar + water or in denture cleanser once a week.',
  FIXED_PRODUCT_ADVICE_HEAD: 'Fixed dental restorations e.g. crowns, bridges, etc.',
  FIXED_PRODUCT_ADVICE:
    'Brush with a soft brush and flour toothpaste. The dentist will give advice on how to clean between the teeth.',

  //new menu
  DENTHUB_USER: 'Users',
  TECHNICIANS: 'Technicians',
  USER_SETTINGS: 'User settings',
  REACT_APP: 'React app',

  //logistics customer
  LOGISTICS_CUSTOMER: 'Logistics customer',
  CONNECT_CLINICS: 'Connect clinics',
  TRANSPORT_CLINICS: 'Transport clinics',
  TRANSPORT_CLINIC: 'Transport clinic',
  ZIP_CODE: 'Zip code',
  NEW_TRANSPORT_CLINIC: 'New transport clinic',
  ADD_TRANSPORT_CLINIC: 'Add transport clinic',
  UPDATE_TRANSPORT_CLINIC: 'Update transport clinic',
  TRANSPORT_CLINIC_ADDED: 'Transport clinic added',
  TRANSPORT_CLINIC_UPDATED: 'Transport clinic updated',
  TRANSPORT_CLINIC_DELETED: 'Transport clinic deleted',
  ONLY_NOT_CONNECTED_CLINICS: 'Only not connected clinics',

  //statuses
  RECEIVED_BY_LAB: 'Received by lab',
  APPROVED_ORDER: 'Approved',
  SENT_DENTIST: 'Sent by dentist',
  SENT_LAB: 'Sent by lab',
  ERROR_IN_STATUS_CHANGE: 'Error in status change',
  RECENTLY_USED_LABS: 'Recently used labs',
  LAST_USED: 'Last used on',
  ALL_STATUSES: 'All statuses',
  ALL_SAMPLE_TYPE: 'All sample type',

  //public API secret key setting
  SECRET_KEY: 'Secret key',
  SECRET_KEY_DESCRIPTION: 'Secret key for all public api of organization',

  //white label report
  WHITE_LABEL_USER_LIST: 'White label user list',
  CREATED_DATE: 'Created date',
  SELECT_GENDER: 'Select Gender',
  GENDER: 'Gender',
  MALE: 'Male',
  FEMALE: 'Female',
  PATIENT_GENDER: 'Patient gender',
  SAVE_DRAFT: 'Save draft',
  SAVE_AND_NEXT: 'Save and next',
  COMPLETE_DRAFT: 'Complete draft',
  PATIENT_GENDER_DESCRIPTION:
    'Activates the function patient gender. This function is only used in actions based orders.',
  COMPLETED_DRAFT: 'Completed draft',
  COMPLETED_DRAFT_DESCRIPTION:
    'Only for action-based orders. Enables user to save order as "completed draft", which can be reviews by dentist before sending the order to lab.',
  DISALLOW_NON_DENTIST: 'Disallow non dentists',
  DISALLOW_NON_DENTIST_DESCRIPTION: 'Disallow non dentists from placing order',

  //image annotation
  CONFIRM_CLOSE: 'Do you want to discard changes?',
  ANNOTATION_INSTRUCTIONS: 'For Image Annotation click on the Edit button',
  DRAW_NEW_IMAGE: 'Draw new image',
  ADD_IMAGE: 'Draw image',
  FILENAME: 'Filename',
  SELECT_IMAGE_BACKGROUND: 'Select background',

  //new statuses
  LAB_WORK_STARTED: 'Work started',
  LAB_WORK_STARTED_BUTTON: 'START PRODUCTION',
  LAB_WORK_STARTED_DESCRIPTION: 'Enables if the status Lab work started for lab',
  CLINIC_RECEIVED_DELIVERY: 'Received delivery',
  CLINIC_RECEIVED_DELIVERY_BUTTON: 'DELIVERY RECEIVED',
  CLINIC_RECEIVED_DELIVERY_DESCRIPTION:
    'Enables if the status Clinic received delivery for dentist',
  AUTO_REMOVE_DRAFT_ORDERS: 'Auto remove draft orders',
  AUTO_REMOVE_DRAFT_ORDERS_DESCRIPTION:
    'The Number of days after which automatic process removes draft orders',

  //API setting
  USER_SYNC: 'User sync',
  USER_SYNC_DESCRIPTION: 'Enable/Disable user sync API.',
  GET_ORDER: 'Get Order',
  GET_ORDER_DESCRIPTION: 'Enable/Disable get order API.',
  GET_REPORT: 'Get report',
  GET_REPORT_DESCRIPTION: 'Enable/Disable get report API.',
  FILE_UPLOAD: 'File upload',
  FILE_UPLOAD_DESCRIPTION: 'Enable/Disable file upload API.',
  DRAFT_SAVED: 'Draft saved',

  //report for orders price per dentist
  SUM_OF_ORDERS_PRICE_PER_DENTIST: 'Sum of orders price, per dentist',

  // Order details tabs
  ORDER_DETAILS: 'Order',
  ORDER_EVENTS: 'Events',
  LAB_INTERNAL: 'Internal',

  PATIENTS: 'Patients',
  SHOW_PATIENT_HISTORY: 'Show all orders for this patient',

  WORKFLOW: 'Workflow',

  COLLECTIVE: 'Collective - Grouped by clinic',
  COLLECTIVE_DENTIST: 'Collective - Grouped by dentist per clinic',

  // New invoice status:
  INVOICE_STATUS_TEMP: 'Created',
  INVOICE_STATUS_TEMP_READY: 'Ready for invoice',
  INVOICE_STATUS_CREATED: 'Created',
  INVOICE_STATUS_PROCESSING: 'Processing',
  INVOICE_STATUS_SENT: 'Sent',
  INVOICE_STATUS_ERROR: '',
  PARTICIPANTS: 'Participants',
  PARTICIPANT_INFO: 'All participants will get notifications regarding this order.',
  ADD_PARTICIPANT: 'ADD PARTICIPANT',
  INVOICING_TYPE: 'Invoice type',
  INVOICING_TYPE_DESCRIPTION: 'Invoice type',
  INVOICING_DELIVERY_DESCRIPTION: 'Invoice delivery method',
  CERTIFICATION: 'Certification',
  CERTIFICATION_DESCRIPTION: 'Invoice certification feature',
  CERTIFIED_BY: 'Certified by',
  CERTIFY_ORDER: 'Certify order',
  CERTIFY: 'Certify',
  CONFIRM_MESSAGE: 'Are you sure?',
  CONFIRM_TITLE: 'Confirm',
  CONFIRM_CERTIFY:
    'Yes, the price from the lab is correct and I certify the invoice.',
  PRODUCTION_FINISHED: 'Production finished',
  PRODUCTION_FINISHED_ACTION: 'Finish production',
  PRODUCTION_NOT_FINISHED: 'Production not finished',
  PRODUCTION_NOT_FINISHED_ACTION: 'Resume production',
  CONFIRM_PRODUCTION_FINISHED:
    'Are you sure you want to flag the order as production finished?',
  CONFIRM_PRODUCTION_NOT_FINISHED:
    'Are you sure you want resume production and change order to "Production not finished"?',
  PRODUCTION_FINISHED_NOTE:
    'Please note: Production finished will not send the order to the clinic or make the order ready for invoice. Make sure to use "Sent by lab" feature at a later stage',
  PRODUCTION_NOT_FINISHED_NOTE:
    'This order is already flagged as production finished. This action will return the order to a "Production ongoing" state and cleary any "Ready for invoice" status.',
  ERROR_IN_PRODUCTION_ORDER: 'Error in production order',
  FINISHED: 'Finished',
  NOT_FINISHED: 'Not finished',
  ORDER_HAS_BEEN_INVOICED: 'This order has been invoiced',
  INVOICING_DELIVERY: 'Invoice delivery',
  DELIVERY_NOTE: 'Delivery notes',
  LAB_ORDER_INFO: 'You can not modify, order created by lab.',
  DO_NOT_INVOICE: 'Do not invoice this order',
  ERROR_IN_DO_NOT_INVOICE: 'Something went wrong',
  DO_NOT_INVOICE_SUCCESSFULLY: 'Order will not be invoiced',
  REACTIVATE_INVOICE: 'Reactivate invoicing',
  INVOICE_STATE: 'Invoicing',
  NOT_READY: 'Not ready to be invoiced',
  SAMPLES_PER_DENTIST: 'Samples, per dentist',
  PHYSICAL_SAMPLES: 'Physical samples',
  DIGITAL_SAMPLES: 'Digital samples',
  NO_SAMPLES: 'No samples',
  DOWNLOAD_DOCUMENTS: 'Documents & print',
  INVOICE: 'Invoice',
  DELIVERY_NOTE_DOWNLOADED_SUCCESSFULLY: 'Delivery note downloaded successfully',
  SEND_TO_STEP: 'Change step',
  NO_STEP: 'No Step',
  WORKFLOW_LOG: 'Workflow log',
  PAUSE_STEP: 'Pause in this step',
  UN_PAUSE_STEP: 'Resume in this step',
  ORDER_IS_PAUSED: 'Order is paused',
  TRANSPORT_TRACKING_LINK: 'Transport tracking link',
  OPEN_TRACKING_PAGE: 'Open tracking page',
  ERROR_IN_TRANSPORT_TRACKING_LINK: 'Error in transport tracking link',
  SELECTED_PREVIOUS_DATE: 'Sample recieved',
  ZERO_PRICE_ORDER: 'Can not invoice a 0 price order',
  DELIVERY_WARNING: 'Different pickup and destination address',
  VISMA: 'Visma',
  VISMA_DESCRIPTION: 'Aktivera/inaktivera Visma integration.',
  FORTNOX: 'Fortnox',
  FORTNOX_DESCRIPTION: 'Enable/Disable the Fortnox integration.',
  FORTNOX_BOOKKEEPING: 'Fortnox Bookkeeping',
  FORTNOX_BOOKKEEPING_DESCRIPTION: 'Enable/Disable the Fortnox bookkeeping feature.',
  FORTNOX_CUSTOMER_NUMBER: 'Fortnox customer number',
  FORTNOX_CUSTOMER_NUMBER_DESCRIPTION: 'Lab Fortnox customer number.',
  FORTNOX_VAT_ACCOUNT_CODES: 'Fortnox VAT account codes',
  FORTNOX_VAT_ACCOUNT_CODES_DESCRIPTION:
    'Lab Fortnox account number codes per VAT type.',
  IN_HOUSE_TRANSPORT: 'In house transport',
  SCAN_CASES: 'Scan orders',
  SCAN_CASES_DESCRIPTION: 'Enable/Disable the Scan Orders feature.',
  SCAN_CASE_EMAIL_WHITE_LIST: 'Scan case email white list for 3Shape',
  SCAN_CASE_EMAIL_WHITE_LIST_DESCRIPTION:
    'Enable/Disable the Scan case email white list feature.',
  THREE_SHAPE_INTEGRATION: '3Shape Integration',
  THREE_SHAPE_INTEGRATION_DESCRIPTION: 'Enable/Disable 3Shape integration.',
  MEDIT_INTEGRATION: 'Medit Integration',
  MEDIT_INTEGRATION_DESCRIPTION: 'Enable/Disable Medit integration.',
  THREE_SHAPE_CLIENT_ID: '3Shape Client ID',
  THREE_SHAPE_CLIENT_ID_DESCRIPTION: 'Client ID for 3shape.',
  SYNC_FROM_DATE: 'Sync scan cases after date',
  SYNC_FROM_DATE_DESCRIPTION:
    'Controls the date from which the Scan cases will be synced. Format: YYYY-MM-DD OR MM/DD/YYYY . Example input: 2023-06-27, 06/27/2023',
  THREE_SHAPE_DCM_FILE_DOWNLOAD: '3Shape DCM file download',
  THREE_SHAPE_DCM_FILE_DOWNLOAD_DESCRIPTION:
    'Enable/Disable 3Shape DCM file download.',
  THREE_SHAPE_PLY_FILE_DOWNLOAD: 'PLY file download',
  THREE_SHAPE_PLY_FILE_DOWNLOAD_DESCRIPTION:
    'Enable/Disable 3Shape/Medit PLY file download.',
  MEDIT_OBJ_FILE_DOWNLOAD: 'Medit OBJ file download',
  MEDIT_OBJ_FILE_DOWNLOAD_DESCRIPTION: 'Enable/Disable Medit OBJ file download.',
  CHECK_ABANDONED_CASES: 'Check abandoned scan cases',
  CHECK_ABANDONED_CASES_DESCRIPTION:
    'Enable/Disable 3Shape check abandoned scan cases.',
  CHAT_TEMPLATE: 'Chat template',
  CHAT_TEMPLATE_DESCRIPTION: 'Enable/Disable chat template feature.',
  USE_CALENDAR_DELIVERY_DAYS: 'Use calendar delivery days',
  USE_CALENDAR_DELIVERY_DAYS_DESCRIPTION:
    'Enable/Disable use calendar delivery days.',
  LAB_MILLING: 'Lab Milling',
  LAB_MILLING_DESCRIPTION: 'Enable/Disable the lab milling feature.',
  EDIT_USER_TITLE: 'Edit User Title',
  EDIT_USER_TITLE_DESCRIPTION: 'Enable/Disable the edit user title feature.',
  TRANSPORT_PROVIDERS: 'Transport providers',
  TRANSPORT_PROVIDERS_DESCRIPTION:
    'eg: [{"externalId": "123", "code": "abc", "channel": "NSHIFT"}]',
  SHOW_TEMPLATE_DIALOG: 'Mallar för meddelanden',
  CREATE_INVOICE: 'Create Invoice',
  IN_HOUSE_TRANSPORT_DESCRIPTION:
    'Enable/Disable the lab in house transport feature.',
  HAS_THE_CONSTRUCTION_BEEN_CEMENTED: 'Has the construction been cemented ?',
  NOT_CEMENTED: 'Not cemented',
  UNKNOWN: 'Cementation unknown',
  INVOICE_IN_ERROR_STATE: 'Invoice in error state',
  INVOICE_NUMBER_FORTNOX_ENABLED:
    'Fortnox will create invoice numbers automatically.',
  CREATE_WARRANTY_ORDER: 'Create warranty order',
  LINKED_ORDERS: 'Linked orders',
  WARRANTY_ORDER_OF: 'Warranty order related to',
  MODIFY_ORDER_INFO:
    'Order cannot be modified when the production is finished and/or is ready for invoice.',
  DEVIATIONS: 'Deviations',
  DISABLED_WHITE_LABEL:
    'Lab not connected OR WhiteLabel is not enabled. Please contact Lab',
  USE_LAB_LOGIN:
    'You need to use the login page provided by the lab for this account. If you have no link to the login page please contact the lab or Denthub on support@denthub.com.',
  INTERNAL: 'Internal',
  EXTERNAL: 'External',
  DEVIATION_ACTION: 'Action',
  INTERNAL_DELIVERY_DATE: 'Production deadline',
  DATE_UNKNOWN: 'No date',
  CHAT_DISABLED_FOR_DENTIST_INFO:
    'Dentist will not be able to respond when the production is finished and/or is ready for invoice.',
  ORDER_INTERACTION_DISABLED_INFO:
    'The production of this order is finished and/or invoiced and interaction has been disabled. For any needed follow-up, please create a new order or create a warranty order.',
  CLOSE: 'Close',
  ERROR_TITLE: 'Wrong login page',
  INTRAORAL_SCAN: 'Intraoral scans',
  ATTACH_INTRAORAL_SCAN: 'Attach intraoral scan',
  THREE_SHAPE_COMMENT: '3Shape comment',
  DESIGN_SETTINGS: 'Design',
  THREE_SHAPE_CASE: '3Shape case',
  ATTACH_THREE_SHAPE_FILES: 'Attach 3Shape files',
  UPDATED: 'Updated',
  SHOW_3SHAPE_CASE: 'Show 3Shape case',
  LINKTYPECONNECTOR6: 'Bridge',
  VENEER: 'Veneer',
  INLAY: 'Inlay',
  ONLAY: 'Onlay',
  ABUTMENTSCREWRETAINEDCROWN: 'Screw retained crown',
  ABUTMENT: 'Abutment',
  CROWNPONTIC: 'Pontic',
  DIGITALMODELPREPUNSECTIONED: '??DIGITALMODELPREPUNSECTIONED??',
  COPING: '??COPING??',
  CLASP: 'Clasp',
  ANATOMICALPONTIC: '??ANATOMICALPONTIC??',
  LOWERJAWSCAN: 'Lower jaw',
  UPPERJAWSCAN: 'Upper jaw',
  BITESCAN: 'Bite 1',
  BITESCAN2: 'Bite 2',
  UPPERIMPLANTSCAN: 'Upper implant',
  LOWERIMPLANTSCAN: 'Lower implant',
  MANUFACTURER: 'Manufacturer',
  CONNECTION: 'Connection',
  DIAMETER: 'Diameter',
  LENGTH: 'Length',
  LINK: 'Link',
  ANY_SHADE: 'Any shade',
  DCM_FILES_TITLE: 'DCM files (3Shape format)',
  PLY_FILES_TITLE: 'PLY files',
  STL_FILES_TITLE: 'STL files',
  SHOW_CASE_AND_DOWNLOAD_FILES: 'Show case and download files',
  DOWNLOAD_3SHAPE_FORM: 'Download 3Shape form',
  OTHER_FILES_TITLE: 'Other files',
  SCAN_FILE_COUNT: 'scans',
  ALL_FILES_COUNT: 'files in total',
  SCAN_CASE: 'Scan case',
  ATTACHED_SCANS: 'Attached scans',
  DENTIST_NOTE: 'Internal note',
  NOTE: 'Note',
  ACTIVE_ACTION_SET: 'Active action set',
  ADD_ACTION_SET: 'Add action set',
  ATTACHED_IMAGE: 'Attached image from driver',
  SHOW_IMAGE: 'Show image',
  PATIENT_LABEL: 'Patient Label',
  DESTINATION_ADDRESS_LABEL: 'Destination address label',
  ANNOTATE: 'Annotate',
  DISCOUNTED_PRICE_INFO:
    'When modifying the order any price deductions/discounts made on this order will reset to the original price.',
  SAML_CONFIG: 'SAML Configuration',
  SAML_CONFIG_DESCRIPTION: 'Configure SAML IDP for this organization.',
  SAML_ENTITY_ID: 'SAML Entity ID',
  SAML_ENTITY_ID_DESCRIPTION: 'SAML Identify Provider Entity ID.',
  SHOW_ORDER_CREATOR_IN_PER_ORDER_INVOICE: 'Show order creator in per order invoice',
  SHOW_ORDER_CREATOR_IN_PER_ORDER_INVOICE_DESCRIPTION:
    'Enable/Disable show creator of an order in the per order invoice feature.',
  PRINT_ORDER_SUMMARY: 'Order summary (A4)',
  COPY_ORDER: 'Copy order',
  CUSTOM_EMAIL: 'Custom email'
};
